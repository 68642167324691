import React, { useEffect, useState, useRef } from "react";

//packages
import styled from "styled-components";

//custom hooks
import useOutsideClick from "../../general/hooks/useOutsideClick";
import useOutsideScroll from "../../general/hooks/useOutsideScroll";

//components
import CountryModal from "../../includes/modal/CountryModal";

export default function CorporateGiftingSpotlight() {
    //states
    const [whatsappNo] = useState(918589998009);
    const [dropDown, setDropDown] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setDropDown(false));
    useOutsideScroll(dropDown);

    //detecting type of the device
    function findDevice() {
        const userAgent = navigator.userAgent.toLowerCase();
        var device = "";
        const mobileKeywords = [
            "mobile",
            "android",
            "ios",
            "iphone",
            "ipad",
            "ipod",
            "blackberry",
            "windows phone",
        ];
        const tabletKeywords = ["tablet", "ipad", "android"];

        for (let i = 0; i < mobileKeywords.length; i++) {
            if (userAgent.indexOf(mobileKeywords[i]) > -1) {
                device = "mobile";
                return device;
            }
        }

        for (let i = 0; i < tabletKeywords.length; i++) {
            if (userAgent.indexOf(tabletKeywords[i]) > -1) {
                device = "tablet";
                return device;
            }
        }
        device = "desktop";
        return device;
    }

    useEffect(() => {
        findDevice();
    }, []);

    //sending whatsapp message to the entered number
    const sendWhatsappMessage = (number) => {
        let userDevice = findDevice();
        if (whatsappNo) {
            if (userDevice === "desktop" || userDevice === "tablet") {
                let url = `https://web.whatsapp.com/send?phone=${number}`;
                url += `&text=${encodeURI("")}&app_absent=0`;
                window.open(url);
            } else {
                let url = `whatsapp://send?phone=${number}`;
                url += `&text=${encodeURI("")}&app_absent=0`;
                window.location.href = url;
            }
        }
    };

    return (
        <Container>
            <CountryModal
                dropDown={dropDown}
                setDropDown={setDropDown}
            />
            <Wrapper className="wrapper">
                <Content>
                    <LeftSection>
                        <Title>
                            <span>Promote</span> your Business
                            <br />
                            through Corporate <span>Gifting</span>
                        </Title>
                    </LeftSection>
                    <RightSection>
                        <SpotLightImg
                            src={require("../../../assets/images/corporate-gifting/corporate-spotlight.png")}
                            alt="corporate-spotlight"
                        />
                        <WhatsappLogoContainer onClick={() => sendWhatsappMessage(whatsappNo)}>
                            <WhatsappLogo
                                src={
                                    require("../../../assets/images/common/whatsapp-logo.svg")
                                        .default
                                }
                                alt="whatsapp-icon"
                            />
                        </WhatsappLogoContainer>
                    </RightSection>
                </Content>
            </Wrapper>
        </Container>
    );
}

const Container = styled.section`
    padding: 150px 0 50px;
    @media all and (max-width: 1080px) {
        padding: 120px 0 40px;
    }
    @media all and (max-width: 980px) {
        padding: 100px 0 35px;
    }
    @media all and (max-width: 768px) {
        padding: 100px 0 30px;
    }
`;
const Wrapper = styled.section``;
const Content = styled.div`
    border: 1px solid #ff5e48;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 61px rgba(80, 80, 80, 0.1);
    border-radius: 16px;
    padding-left: 50px;
    @media all and (max-width: 1280px) {
        padding: 0;
    }
    @media all and (max-width: 900px) {
        flex-direction: column;
        border: none;
        box-shadow: none;
    }
`;
const LeftSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 1280px) {
        width: 100%;
        padding-left: 50px;
    }

    @media all and (max-width: 900px) {
        padding: 50px 0 0 0;
        margin-bottom: 50px;
    }
    @media all and (max-width: 640px) {
        padding: 30px 0 0 0;
        margin-bottom: 30px;
    }
`;

const Title = styled.h1`
    font-family: "dm_sansboldbold";
    font-size: 38px;
    color: #282c3f;
    width: 90%;
    span {
        color: #ff5e48;
    }
    @media all and (max-width: 1420px) {
        font-size: 36px;
    }

    @media all and (max-width: 1160px) {
        font-size: 34px;
    }

    @media all and (max-width: 1100px) {
        font-size: 32px;
    }
    @media all and (max-width: 1050px) {
        font-size: 30px;
    }
    @media all and (max-width: 980px) {
        font-size: 28px;
        width: 95%;
    }
    @media all and (max-width: 900px) {
        text-align: center;
        width: 100%;
    }
    @media all and (max-width: 640px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 350px) {
        font-size: 22px;
    }
`;

const WhatsappLogoContainer = styled.div`
    width: 60px;
    font-size: 20px;
    position: absolute;
    z-index: 25;
    right: 0;
    bottom: -100px;
    cursor: pointer;
    @media all and (max-width: 1280px) {
        bottom: -90px;
        width: 50px;
    }
    @media all and (max-width: 980px) {
        width: 50px;
    }
    @media all and (max-width: 900px) {
        width: 40px;
        bottom: -70px;
    }
    @media all and (max-width: 640px) {
        bottom: -60px;
    }

    /* animation-name: scale-in-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    @keyframes scale-in-out {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.1);
        }
    } */
`;

const WhatsappLogo = styled.img``;

const RightSection = styled.div`
    position: relative;
    width: 55%;
    @media all and (max-width: 1390px) {
        width: 50%;
    }
    @media all and (max-width: 1280px) {
        width: 80%;
    }
    @media all and (max-width: 900px) {
        border: 1px solid #ff5e48;
        border-radius: 16px;
        margin: 0 auto;
        width: 100%;
    }
`;
const SpotLightImg = styled.img`
    border-radius: 0 0 16px 0;
`;
