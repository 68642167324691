import React, { useEffect } from "react";

//packages
import styled from "styled-components";

//custom hooks
import useOutsideScroll from "../../general/hooks/useOutsideScroll";

function VerifyOtpModal({
    email,
    resendOtp,
    verifyOtp,
    viewOtpModal,
    setSaveEdit,
    setViewOtpModal,
    setEdit,
    setEmail,
    mailError,
}) {
    useOutsideScroll(viewOtpModal);

    return (
        <div>
            {" "}
            {viewOtpModal && !mailError && (
                <Overlay
                    onClick={() => {
                        setViewOtpModal(false);
                        setEdit(null);
                        setEmail(null);
                        setSaveEdit("");
                    }}
                >
                    <OtpModal onClick={(e) => e.stopPropagation()}>
                        <TopBox>
                            <Title>Verify OTP</Title>
                            <ResendButton onClick={() => resendOtp()}>Resend OTP</ResendButton>
                        </TopBox>
                        <EmailInput placeholder={`Enter OTP Send to ${email}`} />
                        <SubmitButton onClick={() => verifyOtp()}>Submit</SubmitButton>
                    </OtpModal>
                </Overlay>
            )}
        </div>
    );
}

const Overlay = styled.section`
    height: 100%;
    width: 100vw;
    background: rgba(188, 188, 188, 0.5);
    display: flex;
    justify-content: center;
    align-content: center;
    position: fixed;
    z-index: 10;
    inset: 0;
`;
const OtpModal = styled.div`
    width: 500px;
    padding: 55px 50px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media all and (max-width: 540px) {
        width: 450px;
    }
    @media all and (max-width: 480px) {
        width: 85%;
        padding: 25px;
    }
`;
const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`;
const Title = styled.span`
    color: #0a0a0a;
`;
const ResendButton = styled.span`
    color: #3e72e9;
    cursor: pointer;
`;
const SubmitButton = styled.span`
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    color: #fff;
    border-radius: 5px;
    padding: 10px 30px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-top: 25px;
`;
const EmailInput = styled.input`
    border: 1px solid #777a8659;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    font-family: "dm_sansregular";
    font-size: 14px;
    cursor: ${({ isEdit }) => (isEdit !== "EMAIL" ? "default" : "text")};
    cursor: ${({ saveEdit }) => (saveEdit !== "EDIT_EMAIL" ? "default" : "text")};
    :not([readonly]):focus {
        outline: 1.5px solid #ff5e48;
    }
`;

export default VerifyOtpModal;
