import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

// import About from "../../screens/About";

import CorporateGifting from "../../screens/CorporateGifting";
// import Register from "../../includes/Login/Register";

// import OtpPage from "../../includes/Login/OtpPage";

// import Cart from "../../screens/giftproductlist/Cart";
// import SuggestionPage from "../../includes/cart/SuggestionPage";
// import GreetingCards from "../../includes/greetingpage/GreetingCards";
// import CardSingle from "../../includes/greetingpage/CardSingle";
// import Cards from "../../screens/Cards";

// import DettLandingPage from "../../screens/DettLandingPage";
// import GiftLandingPage from "../../screens/gift/GiftLandingPage";
// import ProductRouter from "./ProductRouter";
// import Address from "../../screens/Address";
// import CartPage from "../../includes/cart/CartPage";

// import DettCartpageHeader from "../../includes/header/DettCartpageHeader";
// import NewAddressModal from "../../modal/NewAddressModal";
// import CouponModal from "../../modal/CouponModal";
// import WishList from "../../screens/WishList";
// import GiftErrorPage from "../../includes/gift/GiftErrorPage";
// import DeviceScreenAddress from "../../includes/AddressHome/DeviceScreenAddress";
// import DeviceScreenNewAddress from "../../includes/AddressHome/DeviceScreenNewAddress";
// import OccasionSubmitForm from "../../includes/Login/OccasionSubmitForm";
// import LoginPage from "../../includes/Login/LoginPage";
// import PrebookLandingPage from "../../includes/Login/PrebookLandingPage";
// import Privacypolicy from "../../screens/Privacypolicy";
// import TermandCondition from "../../screens/TermandCondition";
// import { Context } from "../../Contexts/Store";
// import GetApp from "../../includes/home/GetApp";
import Loader from "../../general/loader/Loader";
import PrivateRoute from "../routes/PrivateRoute";
// import Profile from "../../includes/userProfile/Profile";
// import Career from "../../screens/Career";
// import WomensDayForm from "../../screens/WomensDayForm";
import GiftProductListing from "../../screens/GiftProductListing";
import AccountRouter from "./AccountRouter";
import ProductSinglepage from "../../screens/ProductSinglepage";
import PartnerSinglePage from "../../screens/corporate-gifting/PartnerSinglePage";
import CartPage from "../../screens/CartPage";

const Career = lazy(() => import("../../screens/Career"));
const DeviceScreenAddress = lazy(() =>
    import("../../includes/AddressHome/DeviceScreenAddress")
);
const DeviceScreenNewAddress = lazy(() =>
    import("../../includes/AddressHome/DeviceScreenNewAddress")
);
const OccasionSubmitForm = lazy(() =>
    import("../../screens/prebook/OccasionSubmitForm")
);
const LoginPage = lazy(() => import("../../screens/prebook/LoginPage"));
const PrebookLandingPage = lazy(() =>
    import("../../screens/prebook/PrebookLandingPage")
);
const Privacypolicy = lazy(() => import("../../screens/Privacypolicy"));
const TermandCondition = lazy(() => import("../../screens/TermandCondition"));
const Address = lazy(() => import("../../screens/Address"));
// const GreetingCards = lazy(() => import("../../includes/greetingpage/GreetingCards"));
const CardSingle = lazy(() => import("../../includes/greetingpage/CardSingle"));
// const Cart = lazy(() => import("../../screens/giftproductlist/Cart"));
const OtpPage = lazy(() => import("../../screens/prebook/OtpPage"));
const Register = lazy(() => import("../../screens/prebook/Register"));
const About = lazy(() => import("../../screens/About"));
const DettLandingPage = lazy(() => import("../../screens/DettLandingPage"));

function AppRouter() {
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<DettLandingPage />} />
                <Route path="/about" element={<About />} />

                <Route path="/prebook" element={<PrebookLandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/otp" element={<OtpPage />} />
                <Route path="/register" element={<Register />} />

                <Route path="/pre-book" element={<OccasionSubmitForm />} />

                <Route path="/privacypolicy" element={<Privacypolicy />} />
                <Route
                    path="/termsandcondition"
                    element={<TermandCondition />}
                />

                {/* <Route
                    path="/account/*"
                    element={
                        <PrivateRoute>
                            <AccountRouter />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/careers"
                    element={<Career />}
                />
                <Route
                    path="giftListing"
                    element={<GiftProductListing />}
                />
                <Route
                    path="/product-single-page"
                    element={<ProductSinglepage />}
                />
                <Route
                    path="/viewcart"
                    element={<CartPage />}
                /> */}
                {/* <Route path="/getapp" element={<GetApp />} /> */}

                {/* <Route path="/gift/*" element={<ProductRouter />} /> */}
                {/* 
          <Route path="/careers" element={<Career />} />
          <Route path="/user-profile" element={<Profile />} />
          {/* <Route path="/careers" element={<Career />} /> */}
                {/* <Route path="/getapp" element={<GetApp />} /> */}

                {/* <Route path="/getapp" element={<GetApp />} /> */}

                {/* <Route path="/gift/*" element={<ProductRouter />} /> */}

                {/* <Route
                    path="/corporategift"
                    element={<CorporateGifting />}
                />
                <Route
                    path="/corporategift/partners/:id"
                    element={<PartnerSinglePage />}
                /> */}

                {/* <Route path="/login" element={<LogIn />} />
          <Route
            path="/resellerRegistration"
            element={<ResellerRegistration />}
          />

          <Route path="/wishlist" element={<WishList />} />
          <Route path="/errorpage" element={<GiftErrorPage />} /> */}
                {/* </Route> */}

                {/* <Route path="/" element={<DettCartpageHeader />}> */}
                {/* <Route path="/cards" element={<GreetingCards />} /> */}
                {/* <Route
                    path="/singlepage"
                    element={<CardSingle />}
                /> */}
                {/* <Route
                    path="/address"
                    element={<Address />}
                /> */}
                {/* <Route path="/addtocart" element={<Cart />} /> */}
                {/* <Route
                    path="/devicescreenaddress"
                    element={<DeviceScreenAddress />}
                /> */}
                {/* <Route
                    path="/devicescreennewaddress"
                    element={<DeviceScreenNewAddress />}
                /> */}
                {/* </Route>  */}
            </Routes>
        </Suspense>
    );
}

export default AppRouter;
