import React from "react";
import styled from "styled-components";

export default function CouponModal({ showAddress, setShowAddress }) {
    return (
        <MainContainer>
            {showAddress == 2 ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: showAddress == 2 && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <SubContainer>
                            <HeadingContainer>
                                <Heading>Apply Coupon</Heading>
                                <CloseButton onClick={() => setShowAddress()}>
                                    <Close
                                        src={require("../../../assets/images/cartImages/ButtonWhite.png")}
                                        alt="close"
                                    />
                                </CloseButton>
                            </HeadingContainer>

                            <PincodeContainer>
                                <PinCodeForm>
                                    <PincodInput
                                        type="number"
                                        placeholder="Enter Pincode"
                                    />
                                    <CheckButton>Check</CheckButton>
                                </PinCodeForm>
                            </PincodeContainer>

                            <CouponContainer>
                                <CouponDetails>
                                    <Top>
                                        <OrangeTick>
                                            <TickContainer>
                                                <TickImg
                                                    src={require("../../../assets/images/cartImages/orangeCheckbox.png")}
                                                    alt="tick"
                                                />
                                            </TickContainer>
                                            <Coupon>
                                                <CouponImg
                                                    src={require("../../../assets/images/cartImages/CouponCode.png")}
                                                    alt="tick"
                                                />
                                            </Coupon>
                                        </OrangeTick>
                                    </Top>

                                    <Bottom>
                                        <Paragraph>
                                            Save ₹ 100 <br />
                                            Rs 100 off on Purchasing of Rs. 999 <br />
                                            Expires on 31 December 2022 <A>|</A> 10 A.M
                                        </Paragraph>
                                    </Bottom>
                                </CouponDetails>

                                <CouponDetails>
                                    <Top>
                                        <OrangeTick>
                                            <TickContainer>
                                                <TickImg
                                                    src={require("../../../assets/images/cartImages/orangeCheckbox.png")}
                                                    alt="checkbox"
                                                />
                                            </TickContainer>
                                            <Coupon>
                                                <CouponImg
                                                    src={require("../../../assets/images/cartImages/CouponCode.png")}
                                                    alt="coupon"
                                                />
                                            </Coupon>
                                        </OrangeTick>
                                    </Top>
                                    <Bottom>
                                        <Paragraph>
                                            Save ₹ 100 <br />
                                            Rs 100 off on Purchasing of Rs. 999 <br />
                                            Expires on 31 December 2022 <A>|</A> 10 A.M
                                        </Paragraph>
                                    </Bottom>
                                </CouponDetails>
                            </CouponContainer>

                            <ButtonContainer>
                                <Button>Apply</Button>
                            </ButtonContainer>
                        </SubContainer>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div``;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    right: 0;
    z-index: 1000;
    left: 0;
    top: 50%;
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 32%;
    position: relative;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #f5f5f6;

    @media all and (max-width: 1150px) {
        width: 35%;
    }

    @media all and (max-width: 1080px) {
        width: 40%;
    }

    @media all and (max-width: 980px) {
        width: 50%;
    }

    @media all and (max-width: 768px) {
        width: 65%;
    }

    @media all and (max-width: 640px) {
        width: 85%;
    }

    @media all and (max-width: 480px) {
        width: 95%;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 82vh; */
    margin: 0 auto;
    border-radius: 8px;

    /* @media all and (max-width: 1280px) {
        height: 93vh;
    }

    @media all and (max-width: 1080px) {
        height: 92vh;
    }

    @media all and (max-width: 480px) {
        height: 84vh;
    }

    @media all and (max-width: 360px) {
        height: 79vh;
    } */
`;

const SubContainer = styled.div`
    width: 100%;
`;

const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #fff;
    height: 50px;
    border-radius: 4px;
`;

const Heading = styled.h1`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-left: 10px;
`;

const CloseButton = styled.div`
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const Close = styled.img`
    width: 100%;
    display: block;
`;

const PincodeContainer = styled.div`
    margin-bottom: 20px;
    background: #fff;
    height: 75px;
    display: flex;
    align-items: center;

    @media all and (max-width: 480px) {
        height: 65px;
    }
`;

const PinCodeForm = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border: 1px solid #d4d4d4;
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    background: #fff;

    @media all and (max-width: 480px) {
        height: 40px;
    }
`;

const PincodInput = styled.input`
    margin-left: 10px;
    width: 80%;
    font-size: 14px;

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const CheckButton = styled.h1`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-right: 10px;
    color: #ff7d6c;
`;

const CouponContainer = styled.div``;

const CouponDetails = styled.div`
    margin-bottom: 15px;
    padding: 20px 30px;
    background: #fff;

    @media all and (max-width: 480px) {
        padding: 12px 30px;
    }
`;

const Top = styled.div`
    margin-bottom: 15px;
`;

const OrangeTick = styled.div`
    display: flex;
    align-items: center;
`;

const TickContainer = styled.div`
    width: 20px;
    margin-right: 10px;

    @media all and (max-width: 360px) {
        width: 16px;
    }
`;
const TickImg = styled.img`
    width: 100%;
    display: block;
`;

const Coupon = styled.div`
    width: 100px;

    @media all and (max-width: 360px) {
        width: 85px;
    }
`;

const CouponImg = styled.img`
    width: 100%;
    display: block;
`;

const Bottom = styled.div``;

const Paragraph = styled.p`
    font-size: 14px;
    margin-left: 30px;
    font-family: "dm_sansregular";

    @media all and (max-width: 360px) {
        font-size: 12px;
    }
`;

const A = styled.span`
    color: #d4d4d4;
`;

const ButtonContainer = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 4px;

    @media all and (max-width: 480px) {
        padding: 15px;
    }
`;

const Button = styled.a`
    display: block;
    height: 40px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 6px;
    font-family: "dm_sansboldmedium";
    cursor: pointer;

    @media all and (max-width: 1080px) {
        height: 45px;
    }

    @media all and (max-width: 980px) {
        height: 35px;
    }

    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
