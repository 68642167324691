import React, { useContext, useEffect, useRef, useState } from "react";

//packages
import { Link, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

//contest
import { Context } from "../../Contexts/Store";

//components
import HamburgerModal from "../../includes/modal/HamburgerModal";

//custom hook
import useOutsideClick from "../../general/hooks/useOutsideClick";
import useOutsideScroll from "../../general/hooks/useOutsideScroll";
import useScreenWidth from "../../general/hooks/useScreenWidth";

//images
import profile from "../../../assets/images/header/profile.svg";
import profileOrange from "../../../assets/images/header/profile-orange.svg";

function Header() {
    //state(store)
    const {
        dispatch,
        state: { user_data, account_details },
    } = useContext(Context);

    //states
    const [hamburgerState, setHamburgerState] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [hovered, setHovered] = useState(false);

    const access_token = user_data?.access_token;
    const currentPath = window.location.pathname;
    const firstName = account_details?.first_name;
    const lastName = account_details?.last_name;
    const phoneNumber = user_data?.phone;

    const profileModalRef = useRef(null);
    const navigate = useNavigate();

    //custom-hooks
    useOutsideClick(profileModalRef, () => setProfileDropdown(false));
    useOutsideScroll(hamburgerState);
    const screenWidth = useScreenWidth();

    //Routes that doesn't need Login button
    const loginRoutes = {
        login: "/login",
        otp: "/otp",
        register: "/register",
        prebook: "/pre-book",
    };

    //logout function
    const logout = () => {
        dispatch({
            type: "LOGOUT",
            user_data: null,
        });
        navigate("/");
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleHamburger = () => {
        setHamburgerState(!hamburgerState);
    };

    //profile dropdown
    const handleDropDown = () => {
        setProfileDropdown(!profileDropdown);
    };

    //header transition function
    var prevScrollpos = window.pageYOffset;
    const handleScroll = () => {
        var currentScrollPos = window.pageYOffset;
        const header = document.getElementById("header");
        if (header) {
            if (prevScrollpos <= 30) {
                header.style.top = "0";
                header.style.transition = "all 0.8s";
            } else {
                header.style.top = "-200px";
                header.style.transition = "all 0.8s";
            }
            prevScrollpos = currentScrollPos;
            setInterval(() => {
                header.style.top = "0";
                header.style.transition = "all 0.8s";
            }, 500);
        }
    };

    //dynamic navigation function
    const navigateTo = () => {
        if (screenWidth > 768) {
            navigate("/account/user-profile");
        } else {
            navigate("/account");
        }
    };

    //scroll to top function
    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <HeaderContainer id="header">
                <NavBar>
                    <Wrapper>
                        <LeftBox>
                            <LogoContainer>
                                <Logo
                                    to="/"
                                    className={currentPath === "/" && "active"}
                                    onClick={() => scrollToTop()}
                                >
                                    <img
                                        src={
                                            require("../../../assets/images/common/dett-logo.svg")
                                                .default
                                        }
                                        alt="header-logo"
                                    />
                                </Logo>
                            </LogoContainer>
                        </LeftBox>
                        <RightBox>
                            <ContentBox>
                                <ButtonBox>
                                    <NavigationButton
                                        to="/about"
                                        onClick={() => {}}
                                        className={currentPath === "/about" && "active"}
                                    >
                                        <span>About Us</span>
                                    </NavigationButton>

                                    {/* <NavigationButton
                                        to="/careers"
                                        onClick={() => {}}
                                        className={currentPath === "/careers" && "active"}
                                    >
                                        <span>Careers</span>
                                    </NavigationButton> */}

                                    {/* <NavigationButton
                                        to="/corporategift"
                                        onClick={() => {}}
                                        className={currentPath === "/corporategift" && "active"}
                                    >
                                        <span>Corporative - Gifting</span>
                                    </NavigationButton> */}
                                    {access_token ? (
                                        <ProfileModalContainer
                                            onClick={() => {
                                                handleDropDown();
                                            }}
                                            onMouseEnter={() => setHovered(true)}
                                            onMouseLeave={() => setHovered(false)}
                                            className={
                                                currentPath === "/account/user-profile" &&
                                                "profile-active"
                                            }
                                        >
                                            <ProfileBox className="profilebox">
                                                <Profile>
                                                    <img
                                                        src={
                                                            hovered ||
                                                            currentPath === "/account/user-profile"
                                                                ? profileOrange
                                                                : profile
                                                        }
                                                        alt="profile"
                                                    />
                                                </Profile>
                                                <span>Profile</span>
                                            </ProfileBox>
                                            {profileDropdown && (
                                                <ProfileModal ref={profileModalRef}>
                                                    <Arrow>
                                                        <img
                                                            src={
                                                                require("../../../assets/images/header/triangle.svg")
                                                                    .default
                                                            }
                                                            alt="triangle"
                                                        />
                                                    </Arrow>
                                                    {user_data?.access_token && (
                                                        <>
                                                            <Button>
                                                                Hello {firstName} {lastName}
                                                            </Button>
                                                            <Button>{phoneNumber}</Button>
                                                            <Border></Border>
                                                        </>
                                                    )}

                                                    <Button onClick={() => navigateTo()}>
                                                        My Account
                                                    </Button>
                                                    <Button onClick={() => logout()}>
                                                        Log Out
                                                    </Button>
                                                </ProfileModal>
                                            )}
                                        </ProfileModalContainer>
                                    ) : currentPath ===
                                      loginRoutes[currentPath.slice(1).replace("-", "")] ? (
                                        ""
                                    ) : (
                                        <NavigationButton
                                            to="/login"
                                            className={`login ${
                                                currentPath === "login" && "active"
                                            }`}
                                        >
                                            <span className="login">Login</span>
                                        </NavigationButton>
                                    )}
                                </ButtonBox>

                                {/* <Profile
                                    to="wishlist"
                                    type={activeData}
                                    onClick={() => setActive("Wishlist")}
                                    className={activeData === "Wishlist" && "active"}
                                >
                                    <Icon>
                                    <img src={wishlist} alt="image" />
                                    </Icon>
                                    <TextColor href="" type={activeData}>
                                    Wishlist
                                    </TextColor>
                                 </Profile>

                                 <Profile
                                    to="addtocart"
                                    type={activeData}
                                    onClick={() => setActive("Cart")}
                                    className={activeData === "Cart" && "active"}
                                >
                                    <Icon className="icon">
                                    <img src={cart} alt="image" />
                                    </Icon>
                                    <TextColor href="" type={activeData}>
                                    Cart
                                    </TextColor>
                                 </Profile> */}
                            </ContentBox>

                            <HamburgerContainer
                                onClick={() => handleHamburger()}
                                className={hamburgerState && "change-color"}
                            >
                                <HumburgImage
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/humburg.svg"
                                    }
                                    alt="humburger"
                                />
                            </HamburgerContainer>
                        </RightBox>
                        <HamburgerModal
                            hamburgerState={hamburgerState}
                            setHamburgerState={setHamburgerState}
                        />
                    </Wrapper>
                </NavBar>
            </HeaderContainer>
            <Outlet />
        </>
    );
}

const HeaderContainer = styled.header`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #eeeeee;
    background-color: rgb(255 255 255);
    backdrop-filter: blur(10px);
    transition: all 0.8s ease 0s;
    transform-origin: top;
    z-index: 100;

    &.show {
        transform: scaleY(1);
        z-index: 10;
    }
    &.hide {
        transform: scaleY(0);
        z-index: 0;
    }
    &.header-show {
        transform: scaleY(1);
        box-shadow: none;
        background-color: transparent;
        backdrop-filter: none;
        z-index: 10;
        padding: 0;
        & .wrapper {
            box-shadow: rgb(0 0 0 / 3%) 0px 16px 24px;
            background-color: rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(10px);
            justify-content: flex-end;
            padding: 10px 20px;
            border-radius: 0 0 10px 10px;
            & .hamburger {
                display: block;
            }
        }
    }
    &.header-hide {
        transform: scaleY(0);
        z-index: 0;
    }

    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const NavBar = styled.section`
    width: 100%;
    display: flex;
    @media all and (max-width: 480px) {
        height: 65px;
    }
`;
const Wrapper = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    padding: 10px 0;
    @media all and (max-width: 768px) {
    }

    @media all and (max-width: 360px) {
        width: 85%;
    }
`;

const LogoContainer = styled.h1`
    width: 80px;
`;

const Logo = styled(Link)`
    width: 80px;
    display: inline-block;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 1080px) {
        width: 70px;
    }

    @media all and (max-width: 980px) {
        width: 62px;
    }

    @media all and (max-width: 768px) {
        width: 60px;
    }

    @media all and (max-width: 640px) {
        width: 55px;
    }
`;

const ButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;

    @media all and (max-width: 980px) {
        display: none;
    }
`;

const RightBox = styled.div`
    display: flex;
    justify-content: end;
`;

//PROFILE MODAL STYLES -->
const ProfileModalContainer = styled.div`
    position: relative;
    cursor: pointer;
    &:hover {
        div.profilebox span {
            color: #ff5e48;
        }
    }
    &.profile-active {
        div.profilebox span {
            color: #ff5e48;
        }
    }
`;

const ProfileModal = styled.div`
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: 60px;
    background: #ffffff;
    box-shadow: 0px 4px 65px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    z-index: 50;
    padding: 15px;
    width: max-content;
    min-width: 300px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`;

const Arrow = styled.span`
    display: inline-block;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translatex(-50%);
`;

const Button = styled.span`
    display: inline-block;
    font-family: "dm_sansregular";
    margin-bottom: 8px;
    padding: 5px;
    cursor: pointer;
    &:nth-child(2) {
        color: #282c3f;
        font-family: "dm_sansboldmedium";
        margin-bottom: 0;
        padding-bottom: 3px;
        cursor: default;
        &:hover {
            color: #282c3f;
        }
    }
    &:nth-child(3) {
        margin-bottom: 0;
        cursor: default;
        &:hover {
            color: #282c3f;
        }
    }
    &:nth-child(5) {
        padding-top: 12px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        color: #ff5e48;
    }
`;

const Border = styled.hr`
    width: 100%;
    background-color: #eeeeee;
    height: 1px;
`;

const NavigationButton = styled(Link)`
    margin-right: 20px;

    &.login {
        border-radius: 5px;
        background-color: #ff7d6c;
        color: #fff;
        padding: 7px 35px;
        font-family: "dm_sansboldmedium";
    }

    :last-child {
        margin-right: 0;
    }

    &.active {
        span {
            font-family: "dm_sansboldmedium";
            font-size: 16px;
            color: #ff5e48;
            :hover {
                color: #ff5e48;
            }
            @media all and (max-width: 1080px) {
                font-size: 13px;
            }
        }
    }
    span {
        font-family: "dm_sansboldmedium";
        font-size: 16px;
        :hover {
            color: #ff5e48;
        }
        @media all and (max-width: 1080px) {
            font-size: 15px;
        }
    }
    &:hover {
        span.login {
            color: #fff;
        }
    }
`;

const ProfileBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    span {
        font-family: "dm_sansboldmedium";
        font-size: 16px;
        @media all and (max-width: 1080px) {
            font-size: 15px;
        }
    }
`;

const ContentBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const Profile = styled.div`
    align-items: center;
    text-align: center;
    cursor: pointer;
    width: 20px;

    @media all and (max-width: 980px) {
        display: none;
    }

    &.active {
        & .icon {
            display: none;
        }
        & .responsive {
            display: block;
        }
    }
`;

//future
const TextColor = styled.a`
    /* font-family: "dm_sansboldbold";
    font-size: 14px;
    color: #000;
    &.color {
        color: red;
    }

    @media all and (max-width: 1080px) {
        font-size: 13px;
    }
    @media all and (max-width: 980px) {
        display: none;
    } */
`;

//future
const Wishlist = styled(Link)`
    /* a {
    font-family: "dm_sansboldbold";
    font-size: 14px;

    @media all and (max-width: 980px) {
      font-size: 14px;
    }
    @media all and (max-width: 480px) {
      display: none;
    }
  } */
`;

//future
const Cart = styled(Link)`
    /* a {
    font-family: "dm_sansboldbold";
    font-size: 14px;

    @media all and (max-width: 980px) {
      font-size: 14px;
    }
    @media all and (max-width: 480px) {
      display: none;
    } */
`;

const HamburgerContainer = styled.div`
    display: none;
    cursor: pointer;
    &:hover {
        filter: invert(60%) sepia(60%) saturate(4380%) hue-rotate(329deg) brightness(100%)
            contrast(115%);
    }
    &.change-color {
        filter: invert(60%) sepia(60%) saturate(4380%) hue-rotate(329deg) brightness(100%)
            contrast(115%);
    }
    @media all and (max-width: 980px) {
        display: block;
        width: 30px;
    }
    @media all and (max-width: 480px) {
        width: 25px;
    }
`;
const HumburgImage = styled.img`
    width: 100%;
    display: block;
`;
const LeftBox = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`;

export default Header;
