import React, { useRef, useState } from "react";

//packages
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

//images
import prevWhite from "../../../assets/images/common/slider-prev-white.svg";
import nextWhite from "../../../assets/images/common/slider-next-white.svg";
import prevGrey from "../../../assets/images/common/slider-prev-grey.svg";
import nextGrey from "../../../assets/images/common/slider-next-grey.svg";

export default function Partners() {
    //state
    const [id, setId] = useState("");
    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    //changing next,prev arrows when hovering
    const toggleArrows = (selected) => {
        setId(selected);
    };

    const partners = [
        {
            id: 1,
            logo: require("../../../assets/images/common/startup-logos/steyp.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 2,
            logo: require("../../../assets/images/common/startup-logos/tegain.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 3,
            logo: require("../../../assets/images/common/startup-logos/jobified.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 4,
            logo: require("../../../assets/images/common/startup-logos/growlius.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 5,
            logo: require("../../../assets/images/common/startup-logos/kinfig.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 6,
            logo: require("../../../assets/images/common/startup-logos/stikcon.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 7,
            logo: require("../../../assets/images/common/startup-logos/steyp.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            id: 8,
            logo: require("../../../assets/images/common/startup-logos/jobified.svg").default,
            paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
    ];

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        rows: 2,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <Container>
            <Wrapper className="wrapper">
                <Content>
                    <Top>
                        <Heading>Connected Partners</Heading>
                        <Description>
                            We are dedicated and committed to our shared goals through partnership
                            that have been instrumental in our success.
                        </Description>
                    </Top>
                    <Bottom>
                        <Slider
                            ref={sliderRef}
                            {...settings}
                        >
                            {partners.map((partner, index) => (
                                <Card key={index}>
                                    <PartnerLogoContainer>
                                        <PartnerLogo
                                            src={partner.logo}
                                            alt="steyp-logo"
                                        />
                                    </PartnerLogoContainer>
                                    <PartnerContent>{partner.paragraph}</PartnerContent>
                                    <ViewMoreButton to={`/corporategift/partners/${partner.id}`}>
                                        View More
                                    </ViewMoreButton>
                                </Card>
                            ))}
                        </Slider>
                    </Bottom>
                    <ButtonContainer style={{ textAlign: "center" }}>
                        <PrevButton
                            className="button"
                            onClick={previous}
                            onMouseEnter={() => toggleArrows("grey-prev")}
                            onMouseLeave={() => toggleArrows("normal-prev")}
                        >
                            {id === "grey-prev" ? (
                                <PrevImg
                                    className="grey"
                                    src={prevGrey}
                                    alt="prev-button"
                                />
                            ) : (
                                <PrevImg
                                    src={prevWhite}
                                    alt="prev-button"
                                />
                            )}
                        </PrevButton>
                        <NextButton
                            className="button"
                            onClick={next}
                            onMouseEnter={() => toggleArrows("grey-next")}
                            onMouseLeave={() => toggleArrows("normal-next")}
                        >
                            {id === "grey-next" ? (
                                <NextImg
                                    className="grey"
                                    src={nextGrey}
                                    alt="prev-button"
                                />
                            ) : (
                                <NextImg
                                    src={nextWhite}
                                    alt="prev-button"
                                />
                            )}
                        </NextButton>
                    </ButtonContainer>
                </Content>
            </Wrapper>
        </Container>
    );
}

const Container = styled.section`
    padding: 50px 0;
    @media all and (max-width: 1280px) {
        padding: 40px 0;
    }
    @media all and (max-width: 980px) {
        padding: 35px 0;
    }
    @media all and (max-width: 768px) {
        padding: 30px 0;
    }
`;
const Wrapper = styled.section``;
const Content = styled.div``;
const Top = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
`;
const Heading = styled.h1`
    font-size: 50px;
    color: #282c3f;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    @media all and (max-width: 1390px) {
        font-size: 44px;
    }
    @media all and (max-width: 768px) {
        font-size: 36px;
    }
    @media all and (max-width: 480px) {
        font-size: 30px;
    }
    @media all and (max-width: 360px) {
        font-size: 28px;
    }
`;
const Description = styled.p`
    width: 57%;
    margin: 0 auto;
    font-size: 16px;
    color: #777a86;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 980px) {
        width: 85%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;
const Bottom = styled.div`
    margin-top: 50px;
    box-shadow: 0px 0px 100px rgba(131, 131, 131, 0.08);
    .slick-list {
        border-radius: 16px;
    }
    .slick-slide div {
        margin: 10px !important;
    }
    .slick-slide div div {
        margin-bottom: 0px !important;
    }
    @media all and (max-width: 1280px) {
        margin-top: 25px;
    }
`;
const Card = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 100px rgba(131, 131, 131, 0.08);
    border-radius: 12px;
    padding: 30px;
    overflow: hidden;
`;
const PartnerLogoContainer = styled.span`
    display: inline-block;
    width: 150px;
    height: 60px;
    padding: 15px 30px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 25.0956px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
`;
const PartnerLogo = styled.img``;
const PartnerContent = styled.p`
    color: #777a86;
    margin-bottom: 20px;
    font-size: 16px;
    @media all and (max-width: 1390px) {
        font-size: 14px;
    }
`;
const ViewMoreButton = styled(Link)`
    display: inline-block;
    color: #777a86;
    text-decoration-line: underline;
    font-size: 14px;
    cursor: pointer;
`;
const ButtonContainer = styled.div`
    margin-top: 20px;
    .button {
        cursor: pointer;
        width: 40px;
    }
`;
const PrevButton = styled.button`
    margin-right: 20px;
    width: 40px;
    height: 40px;
`;
const NextButton = styled.button`
    width: 40px;
    height: 40px;
`;
const PrevImg = styled.img``;
const NextImg = styled.img``;
