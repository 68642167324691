import React, { useState } from "react";

//packages
import styled from "styled-components";

//images
import gift from "../../assets/images/common/gift.svg";

export default function ReusableButton({ title, type, styleProp, onClickFunction }) {
    //states
    const [buttonImage, setButtonImage] = useState("");
    const [hovered, setHovered] = useState(false);

    return (
        <ButtonDiv
            id="reusable-button"
            onMouseEnter={() => setButtonImage(true)}
            onClick={onClickFunction}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={hovered ? "animate" : ""}
        >
            <Text styleProp={styleProp}>{title}</Text>
            <ImageDiv type={buttonImage}>
                <GiftImg
                    src={gift}
                    alt="gift"
                />
            </ImageDiv>
        </ButtonDiv>
    );
}

const ButtonDiv = styled.div`
    height: 45px;
    width: 130px;
    border-radius: 5px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease !important;

    &.animate {
        div {
            opacity: 1;
            transition: all 0.4s ease;
        }
        h1 {
            transform: translateX(-15px);
            transition: all 0.4s ease;
        }
    }

    @media all and (max-width: 640px) {
        width: 110px;
        height: 42px;
    }
    @media all and (max-width: 480px) {
        width: 100px;
    }
`;

const Text = styled.h1`
    color: #fff;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    transition: all 0.4s ease;
`;

const ImageDiv = styled.div`
    width: 18px;
    opacity: 0;
    transition: all 0.4s ease;
    position: absolute;
    right: 12px;
`;

const GiftImg = styled.img`
    width: 100%;
    display: block;
`;
