import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

//components
import DettLandingPageHeader from "../../components/includes/header/DettLandingPageHeader";
import HomeFooterpage from "./home/HomeFooterpage";

export default function GiftProductListing() {
    const occasions = [
        {
            id: 0,
            occasion: "Birthday",
            count: "(2000)",
        },

        {
            id: 1,
            occasion: "Wedding Anniversary",
            count: "(1000)",
        },

        {
            id: 2,
            occasion: "Anniversay",
            count: "(2500)",
        },

        {
            id: 4,
            occasion: "Baptism",
            count: "(3200)",
        },
    ];

    const Brands = [
        {
            id: 0,
            brand_name: "Vanhusean",
            count: "(2000)",
        },

        {
            id: 1,
            brand_name: "Calvin Klein",
            count: "(1000)",
        },

        {
            id: 2,
            brand_name: "Fossil",
            count: "(2500)",
        },

        {
            id: 3,
            brand_name: "Huami",
            count: "(3000)",
        },
    ];

    const product_prize = [
        {
            id: 0,
            prize_details: "Less than 500",
            prize_count: "(2000)",
        },

        {
            id: 1,
            prize_details: "Rs. 500 to 1000",
            prize_count: "(2000)",
        },

        {
            id: 2,
            prize_details: "Rs. 1000 to 1500",
            prize_count: "(2000)",
        },

        {
            id: 3,
            prize_details: "Rs. 1500 to 2000",
            prize_count: "(2000)",
        },

        {
            id: 4,
            prize_details: "2000 and More",
            prize_count: "(2000)",
        },
    ];

    const proudct_color = [
        {
            id: 0,
            color_name: "Red",
            color_count: "(2000)",
        },

        {
            id: 1,
            color_name: "Blue",
            color_count: "(2000)",
        },

        {
            id: 2,
            color_name: "Black",
            color_count: "(2000)",
        },

        {
            id: 3,
            color_name: "Green",
            color_count: "(2000)",
        },

        {
            id: 4,
            color_name: "White",
            color_count: "(2000)",
        },
    ];

    const discount = [
        {
            id: 0,
            discound_range: "10% and Above",
        },

        {
            id: 1,
            discound_range: "20% and Above",
        },

        {
            id: 2,
            discound_range: "30% and Above",
        },

        {
            id: 3,
            discound_range: "40% and Above",
        },

        {
            id: 4,
            discound_range: "50% and Above",
        },

        {
            id: 5,
            discound_range: "60% and Above",
        },

        {
            id: 6,
            discound_range: "70% and Above",
        },

        {
            id: 7,
            discound_range: "80% and Above",
        },
    ];

    const product_list = [
        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 1,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 0,
            image_url: require("../../assets/images/gift-product-listing/WatchOne.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },
    ];
    return (
        <>
            <Dettheader>
                <DettLandingPageHeader />
            </Dettheader>

            <GiftProductHeader>
                <HeaderItemsContainer className="wrapper">
                    <HeaderLeftSide>
                        <BackArrowImageContainer>
                            <BackArrowImage
                                src={
                                    require("../../assets/images/gift-product-listing/backArrowImage.svg")
                                        .default
                                }
                            />
                        </BackArrowImageContainer>
                        <PageHeading>Watches</PageHeading>
                    </HeaderLeftSide>

                    <HeaderRightSide>
                        <HeaderIconsContainer>
                            <SearchIconContainer>
                                <SearchIcon
                                    src={
                                        require("../../assets/images/gift-product-listing/headerSearchIcon.svg")
                                            .default
                                    }
                                />
                            </SearchIconContainer>
                            <CartIconContainer>
                                <SearchIcon
                                    src={
                                        require("../../assets/images/gift-product-listing/cartIcon.svg")
                                            .default
                                    }
                                />
                            </CartIconContainer>
                            <GiftIconContainer>
                                <SearchIcon
                                    src={
                                        require("../../assets/images/gift-product-listing/giftIcon.svg")
                                            .default
                                    }
                                />
                            </GiftIconContainer>
                        </HeaderIconsContainer>
                    </HeaderRightSide>
                </HeaderItemsContainer>
            </GiftProductHeader>

            <ColouredContainer></ColouredContainer>
            <MainContainer>
                <SubContainer className="wrapper">
                    <TopContainer>
                        <SearchBarContainer>
                            <InputFieldContainer>
                                <SerachImageContainer>
                                    <SearchImage
                                        src={
                                            require("../../assets/images/gift-product-listing/SearchIcon.svg")
                                                .default
                                        }
                                        alt="search-image"
                                    />
                                </SerachImageContainer>
                                <SearchForm>
                                    <SerachInput
                                        type="text"
                                        placeholder="Watches"
                                    />
                                </SearchForm>
                            </InputFieldContainer>
                            <OpenCloserContainer>
                                <CloseImage
                                    src={
                                        require("../../assets/images/gift-product-listing/closeImage.svg")
                                            .default
                                    }
                                    alt="close-image"
                                />
                            </OpenCloserContainer>
                        </SearchBarContainer>
                    </TopContainer>

                    <BottomContainer>
                        <TopSection>
                            <WatchDetailsContainer>
                                <GiftOrWatch>
                                    Gift /<ProductType>Watch</ProductType>{" "}
                                </GiftOrWatch>
                                <GiftName>Watches</GiftName>
                                <WatchCount>100 + Results</WatchCount>
                            </WatchDetailsContainer>

                            <WatchFilter>
                                <SelectionSort>
                                    Sort by : <Selection>Recommended</Selection>
                                </SelectionSort>
                                <ArrowImageContainer>
                                    <ArrowImage
                                        src={
                                            require("../../assets/images/gift-product-listing/arrowImage.svg")
                                                .default
                                        }
                                        alt="Arrow-Image"
                                    />
                                </ArrowImageContainer>
                            </WatchFilter>
                        </TopSection>

                        <BottomSection>
                            <LeftSideContainer>
                                <FilterContainer>
                                    <FilterHeading>Filter</FilterHeading>
                                    <ClearHeading>Clear All</ClearHeading>
                                </FilterContainer>

                                <FilterSearchContainer>
                                    <FilterSearchField
                                        type="text"
                                        placeholder="Search For Occasions"
                                    />
                                    <SearchCloseImage>
                                        <SerachClose
                                            src={
                                                require("../../assets/images/gift-product-listing/closeImage.svg")
                                                    .default
                                            }
                                            alt="close-image"
                                        />
                                    </SearchCloseImage>
                                </FilterSearchContainer>

                                <OccasionItems>
                                    {occasions.map((item, index, id) => (
                                        <OccasionsList key={id}>
                                            <TickBoxContainer>
                                                <TickBoxImage
                                                    src={
                                                        require("../../assets/images/gift-product-listing/checkBoxGray.svg")
                                                            .default
                                                    }
                                                    alt="check box"
                                                />
                                            </TickBoxContainer>
                                            <OccasionLabel>{item.occasion}</OccasionLabel>
                                            <ProductsCountLabel>{item.count}</ProductsCountLabel>
                                        </OccasionsList>
                                    ))}
                                    <ProductFilterSuggestion>+ 12 More</ProductFilterSuggestion>
                                </OccasionItems>

                                <BrandContainer>
                                    <BrandLabelAndImage>
                                        <BrandLabel>Brand</BrandLabel>
                                        <BrandSearchContainer>
                                            <BrandSerachImage
                                                src={
                                                    require("../../assets/images/gift-product-listing/searchImage.svg")
                                                        .default
                                                }
                                            />
                                        </BrandSearchContainer>
                                    </BrandLabelAndImage>

                                    <ProductBrandConntainer>
                                        {Brands.map((item, index) => (
                                            <MajorBrands key={index}>
                                                <TickBoxContainer>
                                                    <TickBoxImage
                                                        src={
                                                            require("../../assets/images/gift-product-listing/checkBoxGray.svg")
                                                                .default
                                                        }
                                                        alt="check box"
                                                    />
                                                </TickBoxContainer>

                                                <BrandNameLabel>{item.brand_name}</BrandNameLabel>

                                                <BrandItemsCount>{item.count}</BrandItemsCount>
                                            </MajorBrands>
                                        ))}
                                        <ProductFilterSuggestion>+ 12 More</ProductFilterSuggestion>
                                    </ProductBrandConntainer>
                                </BrandContainer>

                                <PrizeContainer>
                                    <ProductPrizeLabel>Prize</ProductPrizeLabel>
                                    <ProductPrizeFilterContainer>
                                        {product_prize.map((item, index) => (
                                            <ProductFilterPrize key={index}>
                                                <TickBoxContainer>
                                                    <TickBoxImage
                                                        src={
                                                            require("../../assets/images/gift-product-listing/checkBoxGray.svg")
                                                                .default
                                                        }
                                                        alt="check box"
                                                    />
                                                </TickBoxContainer>
                                                <PrizeLabel>{item.prize_details}</PrizeLabel>
                                                <PrizeCount>{item.prize_count}</PrizeCount>
                                            </ProductFilterPrize>
                                        ))}
                                    </ProductPrizeFilterContainer>
                                    <MorePrizeLabel>+ 14 More</MorePrizeLabel>
                                </PrizeContainer>

                                <ProductColourContainer>
                                    <ColourLabel>Colour</ColourLabel>
                                    <ColourFilterContainer>
                                        {proudct_color.map((item, index) => (
                                            <ProductColours key={index}>
                                                <TickBoxContainer>
                                                    <TickBoxImage
                                                        src={
                                                            require("../../assets/images/gift-product-listing/checkBoxGray.svg")
                                                                .default
                                                        }
                                                        alt="check box"
                                                    />
                                                </TickBoxContainer>
                                                <BulletColour
                                                    type={proudct_color[index].color_name}
                                                ></BulletColour>
                                                <ProductColour>{item.color_name}</ProductColour>
                                                <ColourCount>{item.color_count}</ColourCount>
                                            </ProductColours>
                                        ))}
                                    </ColourFilterContainer>
                                    <AdditionalColourCount>+ 14 More</AdditionalColourCount>
                                </ProductColourContainer>

                                <DiscountContainer>
                                    <DiscountLabel>Discount Range</DiscountLabel>
                                    <DiscountFilterContainer>
                                        {discount.map((item, index) => (
                                            <ProductDiscountList key={index}>
                                                <TickBoxContainer>
                                                    <TickBoxImage
                                                        src={
                                                            require("../../assets/images/gift-product-listing/checkBoxGray.svg")
                                                                .default
                                                        }
                                                        alt="check box"
                                                    />
                                                </TickBoxContainer>
                                                <ProductDicountLabel>
                                                    {item.discound_range}
                                                </ProductDicountLabel>
                                            </ProductDiscountList>
                                        ))}
                                    </DiscountFilterContainer>
                                </DiscountContainer>
                            </LeftSideContainer>

                            <MiddleSection></MiddleSection>

                            <RightSideContainer>
                                <ProductDetailsContainer>
                                    {product_list.map((item, index) => (
                                        <ProductAndDiscriptonContainer key={index}>
                                            <ProductImageContainer>
                                                <ProductImage
                                                    src={item.image_url.default}
                                                    alt="watch image"
                                                />
                                            </ProductImageContainer>
                                            <ProductNameLabel>{item.proudct_name}</ProductNameLabel>
                                            <ProductCategory>{item.product_title}</ProductCategory>
                                            <ProductPrizeContainer>
                                                <ProductCurrentPrize>
                                                    {item.product_current_price}
                                                </ProductCurrentPrize>
                                                <ProductOrginalPrize>
                                                    {item.product_og_price}
                                                </ProductOrginalPrize>
                                                <ProductOfferLabel>
                                                    {item.product_discount}
                                                </ProductOfferLabel>
                                            </ProductPrizeContainer>
                                        </ProductAndDiscriptonContainer>
                                    ))}
                                </ProductDetailsContainer>
                            </RightSideContainer>
                        </BottomSection>
                    </BottomContainer>
                </SubContainer>
            </MainContainer>
            <SortAndFilterContainer>
                <SortButtonContainer>
                    <SortIconContainer>
                        <SortIcon
                            src={
                                require("../../assets/images/gift-product-listing/sortImage.svg")
                                    .default
                            }
                        />
                    </SortIconContainer>
                    <SortLabel>Sort</SortLabel>
                </SortButtonContainer>

                <FilterButtonContainer>
                    <FilterIconContainer>
                        <FilterIcon
                            src={
                                require("../../assets/images/gift-product-listing/filterImage.svg")
                                    .default
                            }
                        />
                    </FilterIconContainer>
                    <FilterLabel>Filter</FilterLabel>
                </FilterButtonContainer>
            </SortAndFilterContainer>
            <FooterContainer>
                <HomeFooterpage />
            </FooterContainer>
            <Outlet />
        </>
    );
}

const Dettheader = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;

const GiftProductHeader = styled.div`
    padding: 25px 0px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
`;

const HeaderItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeaderLeftSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BackArrowImageContainer = styled.div`
    width: 25px;
    margin-right: 10px;
`;

const BackArrowImage = styled.img`
    width: 100%;
    display: block;
`;

const PageHeading = styled.h1`
    font-size: 16px;
`;

const HeaderRightSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeaderIconsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SearchIconContainer = styled.div`
    width: 25px;
    margin-right: 20px;
`;

const SearchIcon = styled.img`
    width: 100%;
    display: block;
`;

const CartIconContainer = styled.div`
    width: 25px;
    margin-right: 20px;
`;

const GiftIconContainer = styled.div`
    width: 25px;
`;

// const GiftProductHeader = styled.div`

// `;

// const GiftProductHeader = styled.div`

// `;
const MainContainer = styled.div`
    /* padding: 140px 0px 50px; */
    /* background: #FFF2F1; */
    padding-bottom: 50px;

    @media all and (max-width: 480px) {
        padding: 30px 0px 50px;
    }
`;

const SubContainer = styled.div`
    @media all and (max-width: 980px) {
        width: 80%;
    }

    @media all and (max-width: 480px) {
        width: 90%;
    }
`;

const ColouredContainer = styled.div`
    width: 100%;
    min-height: 150px;
    background: #fff2f1;

    @media all and (max-width: 768px) {
        min-height: 130px;
    }

    @media all and (max-width: 480px) {
        display: none;
    }
`;
const TopContainer = styled.div`
    margin-bottom: 50px;
    position: relative;

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const SearchBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    width: 45%;
    padding: 18px 20px;
    border-radius: 30px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    background: #fff;

    @media all and (max-width: 980px) {
        padding: 15px 20px;
        width: 50%;
    }
`;

const InputFieldContainer = styled.div`
    display: flex;
`;

const SerachImageContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;

const SearchImage = styled.img`
    width: 100%;
    display: block;
`;

const SearchForm = styled.div``;

const SerachInput = styled.input`
    display: inline-block;
`;

const OpenCloserContainer = styled.div`
    width: 20px;
    cursor: pointer;
`;

const CloseImage = styled.img`
    width: 100%;
    display: block;
`;

const BottomContainer = styled.div``;

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const WatchDetailsContainer = styled.div``;

const GiftOrWatch = styled.h2`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "dm_sansregular";
    color: #777a86;
    margin-bottom: 5px;

    @media all and (max-width: 768px) {
        margin-bottom: 0px;
    }
`;

const ProductType = styled.span`
    display: inline-block;
    font-size: 16px;
    font-family: "dm_sansregular";
    color: #282c3f;
    margin-left: 4px;
`;

const GiftName = styled.h1`
    font-size: 28px;
    font-family: "dm_sansboldmedium";
    color: #282c3f;
    margin-bottom: 5px;

    @media all and (max-width: 980px) {
        font-size: 24px;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 0px;
    }
`;

const WatchCount = styled.h3`
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const WatchFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eeeeee;
    padding: 10px 15px;
    border-radius: 5px;
`;

const SelectionSort = styled.h2`
    display: flex;
    align-items: center;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const Selection = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-left: 4px;
    margin-right: 10px;
`;

const ArrowImageContainer = styled.div`
    width: 12px;
`;

const ArrowImage = styled.img`
    width: 100%;
    display: block;
`;

const BottomSection = styled.div`
    display: flex;
    border-top: 1px solid #eeeeee;
    /* padding: 15px 0px; */
    @media all and (max-width: 480px) {
        border-top: unset;
    }
`;

const LeftSideContainer = styled.div`
    width: 22%;
    margin-right: 25px;
    margin-top: 15px;

    @media all and (max-width: 1280px) {
        width: 25%;
    }

    @media all and (max-width: 1150px) {
        width: 30%;
    }

    @media all and (max-width: 980px) {
        width: 35%;
    }

    @media all and (max-width: 768px) {
        width: 45%;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    @media all and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const FilterHeading = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }

    @media all and (max-width: 768px) {
        font-size: 16px;
    }
`;

const ClearHeading = styled.span`
    display: inline-block;
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;

const FilterSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #f4f4f4;
    padding: 6px 20px;
    border-radius: 30px;

    @media all and (max-width: 768px) {
        padding: 10px 20px;
    }
`;

const FilterSearchField = styled.input`
    color: #777a86;
    font-size: 12px;
    font-family: "dm_sansregular";
`;

const SearchCloseImage = styled.div`
    width: 20px;
    cursor: pointer;
`;

const SerachClose = styled.img`
    width: 100%;
    display: block;
`;

const OccasionItems = styled.ul`
    margin-bottom: 25px;
`;

const OccasionsList = styled.li`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0px;
    }
`;

const ProductFilterSuggestion = styled.h1`
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansregular";
`;

const BrandContainer = styled.div`
    margin-bottom: 20px;
`;

const BrandLabelAndImage = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const BrandLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const BrandSearchContainer = styled.div`
    width: 30px;
`;

const BrandSerachImage = styled.img`
    width: 100%;
    display: block;
`;

const ProductBrandConntainer = styled.ul``;

const MajorBrands = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const BrandNameLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const BrandItemsCount = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const PrizeContainer = styled.div`
    margin-bottom: 20px;
`;

const ProductPrizeLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const ProductPrizeFilterContainer = styled.ul`
    margin-bottom: 20px;
`;

const ProductFilterPrize = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const PrizeLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const PrizeCount = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const MorePrizeLabel = styled.h1`
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansregular";
`;

const ProductColourContainer = styled.div`
    margin-bottom: 20px;
`;

const ColourLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const ColourFilterContainer = styled.ul``;

const ProductColours = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const BulletColour = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;

    background-color: ${({ type }) =>
        type === "Red"
            ? "#DF0707"
            : type === "Blue"
            ? "#0710DF"
            : type === "Black"
            ? "#090808"
            : type === "Green"
            ? "#25B800"
            : type === "White"
            ? "#FFFFFF"
            : ""};
`;

const ProductColour = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const ColourCount = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const AdditionalColourCount = styled.h1`
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;

const DiscountContainer = styled.div``;

const DiscountLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const DiscountFilterContainer = styled.ul``;

const ProductDiscountList = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ProductDicountLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const TickBoxContainer = styled.div`
    width: 18px;
    cursor: pointer;
    margin-right: 10px;
`;

const TickBoxImage = styled.img`
    width: 100%;
    display: block;
`;

const OccasionLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const ProductsCountLabel = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const MiddleSection = styled.div`
    border-right: 1px solid #eeeeee;
    margin-right: 25px;

    @media all and (max-width: 980px) {
        margin-right: 17px;
    }

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const RightSideContainer = styled.div`
    width: 75%;
    margin-top: 15px;

    @media all and (max-width: 1080px) {
        width: 65%;
    }

    @media all and (max-width: 980px) {
        width: 60%;
    }

    @media all and (max-width: 768px) {
        width: 45%;
    }

    @media all and (max-width: 480px) {
        width: 100%;
        margin-top: 0px;
    }
`;

const ProductDetailsContainer = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;

    @media all and (max-width: 1150px) {
        grid-gap: 25px 15px;
    }

    @media all and (max-width: 1080px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media all and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media all and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const ProductAndDiscriptonContainer = styled.li``;

const ProductImageContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;

const ProductImage = styled.img`
    width: 100%;
    display: block;
`;

const ProductNameLabel = styled.h1`
    color: #282c3f;
    font-size: 16px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const ProductCategory = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-bottom: 5px;
`;

const ProductPrizeContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ProductCurrentPrize = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-right: 5px;

    @media all and (max-width: 1150px) {
        font-size: 12px;
    }

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const ProductOrginalPrize = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 12px;
    font-family: "dm_sansregular";
    margin-right: 5px;
    position: relative;

    @media all and (max-width: 480px) {
        font-size: 10px;
    }

    ::before {
        content: "";
        display: block;
        width: 50px;
        height: 1px;
        background-color: #777a86;
        position: absolute;
        top: 8px;

        @media all and (max-width: 480px) {
            width: 40px;
            top: 6px;
        }
    }
`;

const ProductOfferLabel = styled.h3`
    color: #ff5e48;
    font-size: 14px;
    font-family: "dm_sansregular";

    @media all and (max-width: 1150px) {
        font-size: 12px;
    }

    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;

const FooterContainer = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;

const SortAndFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    min-height: 12%;
`;

const SortButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
    padding: 12px 40px;
    width: 45%;
    margin: 0 auto;
`;

const SortIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;

const SortIcon = styled.img`
    width: 100%;
    display: block;
`;

const SortLabel = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;

const FilterButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
    padding: 12px 40px;
    width: 45%;
    margin: 0 auto;
`;

const FilterIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;

const FilterIcon = styled.img`
    width: 100%;
    display: block;
`;

const FilterLabel = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;
