import React from "react";
import styled from "styled-components";

//packages
import { Link } from "react-router-dom";

export default function NewAddressModal({ showAddress, setShowAddress }) {
    return (
        <MainContainer>
            {showAddress == 1 ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: showAddress == 1 && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <SubContainer>
                            <HeadingContainer>
                                <Heading>Change Delivery Address</Heading>
                                <CloseButton>
                                    <Close
                                        onClick={() => setShowAddress()}
                                        src={require("../../../assets/images/cartImages/ButtonWhite.png")}
                                        alt="close"
                                    />
                                </CloseButton>
                            </HeadingContainer>

                            <AddressDetailsContainer>
                                <AddressContainer>
                                    <Name>Full Name*</Name>
                                    <NameDiv>
                                        <InputName
                                            type="text"
                                            placeholder="Enter Name"
                                        />
                                    </NameDiv>
                                </AddressContainer>

                                <NumberContainer>
                                    <Number>Mobile Number*</Number>
                                    <NumberDiv>
                                        <InputNumber
                                            type="number"
                                            placeholder="Enter Mobile Number"
                                        />
                                    </NumberDiv>
                                </NumberContainer>

                                <PinCodeContainer>
                                    <PinCode>Pincode*</PinCode>
                                    <Pin>
                                        <Input
                                            type="text"
                                            placeholder="Address ( House No, Building, Street, Area )"
                                        />
                                    </Pin>
                                </PinCodeContainer>

                                <LocalityContainer>
                                    <Locality>
                                        <Input
                                            type="text"
                                            placeholder="Locality / Town"
                                        />
                                        <ArrowImage>
                                            <Arrow
                                                src={require("../../../assets/images/cartImages/ArrowButton.png")}
                                                alt="arrow"
                                            />
                                        </ArrowImage>
                                    </Locality>
                                </LocalityContainer>

                                <LocationContainer>
                                    <LocalityLabel>Kollam</LocalityLabel>
                                    <TownLabel>Kottarakkara</TownLabel>
                                </LocationContainer>

                                <SettingsContainer>
                                    <TickImage>
                                        <Tick
                                            src={require("../../../assets/images/cartImages/orangeCheckbox.png")}
                                            alt="tick"
                                        />
                                    </TickImage>
                                    <Paragraph>Make this as my default address</Paragraph>
                                </SettingsContainer>

                                <ButtonContainer>
                                    <Button>Add Address</Button>
                                </ButtonContainer>
                            </AddressDetailsContainer>
                        </SubContainer>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    right: 0;
    z-index: 1000;
    left: 0;
    top: 50%;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const MainContainer = styled.div``;

const Modal = styled.div`
    width: 32%;
    position: relative;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #f5f5f6;

    @media all and (max-width: 1150px) {
        width: 40%;
    }

    @media all and (max-width: 980px) {
        width: 50%;
    }

    @media all and (max-width: 768px) {
        width: 55%;
    }

    @media all and (max-width: 640px) {
        width: 75%;
    }

    @media all and (max-width: 480px) {
        width: 88%;
    }

    @media all and (max-width: 360px) {
        width: 95%;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 80vh; */
    margin: 0 auto;
    border-radius: 8px;

    /* @media all and (max-width: 1280px) {
        height: 91vh;
    }

    @media all and (max-width: 1080px) {
        height: 90vh;
    }

    @media all and (max-width: 768px) {
        height: 88vh;
    }

    @media all and (max-width: 480px) {
        height: 85vh;
    } */
`;

const SubContainer = styled.div`
    width: 100%;
`;

const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #fff;
    height: 55px;
    border-radius: 4px;

    @media all and (max-width: 768px) {
        height: 50px;
    }
`;

const Heading = styled.h1`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-left: 10px;
`;

const CloseButton = styled.div`
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const Close = styled.img`
    width: 100%;
    display: block;
`;

const AddressDetailsContainer = styled.div`
    background: #fff;
    padding: 20px;
`;

const AddressContainer = styled.div`
    margin-bottom: 15px;
`;

const Name = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 5px;

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;

const NameDiv = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 5px;
`;

const InputName = styled.input`
    height: 40px;
    margin-left: 10px;
    font-size: 14px;
    font-family: "dm_sansregular";

    @media all and (max-width: 480px) {
        height: 35px;
        font-size: 12px;
    }
`;

const NumberContainer = styled.div`
    margin-bottom: 15px;
`;

const Number = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 5px;

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;

const NumberDiv = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 5px;
`;

const InputNumber = styled.input`
    height: 40px;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-left: 10px;
    width: 100%;

    @media all and (max-width: 480px) {
        height: 35px;
        font-size: 12px;
    }
`;

const PinCodeContainer = styled.div`
    margin-bottom: 15px;
`;

const PinCode = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 5px;

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;

const Pin = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 5px;
`;

const Input = styled.input`
    height: 40px;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-left: 10px;
    width: 100%;

    @media all and (max-width: 480px) {
        height: 35px;
        font-size: 12px;
    }
`;

const LocalityContainer = styled.div`
    margin-bottom: 15px;
`;

const Locality = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding-right: 10px;
`;

const ArrowImage = styled.div`
    width: 10px;
`;

const Arrow = styled.img`
    width: 100%;
    display: block;
`;

const LocationContainer = styled.div`
    margin-bottom: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const LocalityLabel = styled.h1`
    font-size: 14px;
    font-family: "dm_sansregular";
    background: #f5f5f6;
    padding: 10px;
    width: 45%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TownLabel = styled.h1`
    font-size: 14px;
    font-family: "dm_sansregular";
    background: #f5f5f6;
    padding: 10px;
    width: 45%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SettingsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const TickImage = styled.div`
    width: 18px;
    margin-right: 10px;
`;

const Tick = styled.img`
    width: 100%;
    display: block;
`;

const Paragraph = styled.p`
    font-size: 14px;
    font-family: "dm_sansboldbold";
`;

const ButtonContainer = styled.div`
    text-align: center;
`;

const Button = styled(Link)`
    display: block;
    height: 40px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 6px;
    font-family: "dm_sansboldmedium";
    cursor: pointer;

    @media all and (max-width: 1080px) {
        height: 45px;
    }

    @media all and (max-width: 980px) {
        height: 35px;
    }

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;
