const Reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };

        case "UPDATE_SEARCH_TEXT":
            return {
                ...state,
                searchedText: action.searchedText,
            };

        case "UPDATE_VERIFIED_STATE":
            return {
                ...state,
                isVerified: action.isVerified,
            };

        case "LOGOUT":
            localStorage.removeItem("user_data");
            localStorage.removeItem("account_details");
            return {
                ...state,
                user_data: null,
                account_details: null,
            };

        case "UPDATE_ACCOUNT_DETAILS":
            const account_details = { ...state.account_details, ...action.account_details };
            localStorage.setItem("account_details", JSON.stringify(account_details));
            return {
                ...state,
                account_details: account_details,
            };

        default:
            return state;
    }
};

export default Reducer;
