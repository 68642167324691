import React from "react";
import { useParams } from "react-router-dom";

//packages
import styled from "styled-components";

function PartnerSinglePage() {
    const { id } = useParams();
    return <MainContainer>PartnerSinglePage {id}</MainContainer>;
}

const MainContainer = styled.section``;

export default PartnerSinglePage;
