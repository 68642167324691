import React from "react";

//css
import "../src/assets/css/Style.css";

//packages
import { BrowserRouter as Router } from "react-router-dom";

//components
import MainRouter from "./components/routing/router/MainRouter";

//store
import Store from "./components/Contexts/Store";

function App() {
    return (
        <>
            <Store>
                <Router>
                    <MainRouter />
                </Router>
            </Store>
        </>
    );
}

export default App;
