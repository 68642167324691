import React, { useState } from "react";
import styled from "styled-components";
import CouponModal from "../../includes/modal/CouponModal";
import DeliveryAddressModal from "../../includes/modal/DeliveryAddressModal";
import NewAddressModal from "../../includes/modal/NewAddressModal";

export default function PlaceOrderPage() {
    const [showAddress, setShowAddress] = useState();
    const handleAddressModal = () => {
        setShowAddress(0);
    };

    return (
        <>
            <DeliveryAddressModal
                handleAddressModal={handleAddressModal}
                showAddress={showAddress}
                setShowAddress={setShowAddress}
            />
            <NewAddressModal
                handleAddressModal={handleAddressModal}
                showAddress={showAddress}
                setShowAddress={setShowAddress}
            />
            <CouponModal
                handleAddressModal={handleAddressModal}
                showAddress={showAddress}
                setShowAddress={setShowAddress}
            />
            <Container>
                <Wrapper className="wrapper">
                    <Content>
                        <LeftSection>
                            <Top>
                                <Address>
                                    <CustomerName>
                                        Deliver to :{" "}
                                        <b>Antonia Simran, 673508</b>
                                    </CustomerName>
                                    <CustomerAddress>
                                        106 DOYERS ST. 8 ARLINGTON DR. 599 NW
                                        BAY BLVD, Newyork 107...
                                    </CustomerAddress>
                                </Address>
                                <ChangeAddressButtom
                                    onClick={() => {
                                        handleAddressModal();
                                    }}
                                >
                                    Change address
                                </ChangeAddressButtom>
                            </Top>
                            <Middle>
                                <ItemFunctions>
                                    <AllItems>
                                        <AllCheckBox type="checkbox" />
                                        <ItemsSelected>
                                            Item’s Selected ( 1/1 )
                                        </ItemsSelected>
                                    </AllItems>
                                    <AllItemsFunctions>
                                        <AllItemButtons>
                                            <p>Remove</p>
                                            <img
                                                src={
                                                    require("../../../assets/images/product-single/delete-icon.svg")
                                                        .default
                                                }
                                                alt="delete-icon"
                                            />
                                        </AllItemButtons>
                                        <AllItemButtons>
                                            <p>Move to Wishlist</p>
                                            <img
                                                src={
                                                    require("../../../assets/images/product-single/wishlist-grey.svg")
                                                        .default
                                                }
                                                alt="wishlist-icon"
                                            />
                                        </AllItemButtons>
                                    </AllItemsFunctions>
                                </ItemFunctions>
                                <ItemsContainer>
                                    <Item>
                                        <Product>
                                            <ProductFrame>
                                                <ProductImgContainer>
                                                    <img
                                                        src={require("../../../assets/images/wishlist/watch.png")}
                                                        alt="product"
                                                    />
                                                </ProductImgContainer>
                                                {/* <CheckBoxContainer>
                                                    <CheckBox className="container">
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </CheckBox>
                                                </CheckBoxContainer> */}
                                            </ProductFrame>
                                            <ProductDetails>
                                                <ProductName>
                                                    Huami Amazfit
                                                </ProductName>
                                                <ProductColor>
                                                    Watch, Black
                                                </ProductColor>
                                                <SizeAndQty>
                                                    <DropDown>
                                                        Size: Onesize
                                                    </DropDown>
                                                    <DropDown>Qty: 1</DropDown>
                                                </SizeAndQty>
                                                <Price>
                                                    <CurrentPrice>
                                                        ₹&nbsp;12000
                                                    </CurrentPrice>
                                                    <MRP>
                                                        ₹&nbsp;MRP&nbsp;17000
                                                    </MRP>
                                                    <Discount>
                                                        (76 &nbsp;%&nbsp;Off)
                                                    </Discount>
                                                </Price>
                                                <ExpectedDelivery>
                                                    Delivery by{" "}
                                                    <span>19 Nov 2022</span>
                                                </ExpectedDelivery>
                                            </ProductDetails>
                                        </Product>
                                        <CloseButton>
                                            <img
                                                src={
                                                    require("../../../assets/images/product-single/close-grey.svg")
                                                        .default
                                                }
                                                alt="close"
                                            />
                                        </CloseButton>
                                    </Item>
                                </ItemsContainer>
                                <ItemsContainer>
                                    <Item>
                                        <Product>
                                            <ProductFrame>
                                                <ProductImgContainer>
                                                    <img
                                                        src={require("../../../assets/images/wishlist/watch.png")}
                                                        alt="product"
                                                    />
                                                </ProductImgContainer>
                                                {/* <CheckBoxContainer>
                                                    <CheckBox className="container">
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </CheckBox>
                                                </CheckBoxContainer> */}
                                            </ProductFrame>
                                            <ProductDetails>
                                                <ProductName>
                                                    Huami Amazfit
                                                </ProductName>
                                                <ProductColor>
                                                    Watch, Black
                                                </ProductColor>
                                                <SizeAndQty>
                                                    <DropDown>
                                                        Size: Onesize
                                                    </DropDown>
                                                    <DropDown>Qty: 1</DropDown>
                                                </SizeAndQty>
                                                <Price>
                                                    <CurrentPrice>
                                                        ₹&nbsp;12000
                                                    </CurrentPrice>
                                                    <MRP>
                                                        ₹&nbsp;MRP&nbsp;17000
                                                    </MRP>
                                                    <Discount>
                                                        (76 &nbsp;%&nbsp;Off)
                                                    </Discount>
                                                </Price>
                                                <ExpectedDelivery>
                                                    Delivery by{" "}
                                                    <span>19 Nov 2022</span>
                                                </ExpectedDelivery>
                                            </ProductDetails>
                                        </Product>
                                        <CloseButton>
                                            <img
                                                src={
                                                    require("../../../assets/images/product-single/close-grey.svg")
                                                        .default
                                                }
                                                alt="close"
                                            />
                                        </CloseButton>
                                    </Item>
                                </ItemsContainer>
                                <ItemsContainer>
                                    <Item>
                                        <Product>
                                            <ProductFrame>
                                                <ProductImgContainer>
                                                    <img
                                                        src={require("../../../assets/images/wishlist/watch.png")}
                                                        alt="product"
                                                    />
                                                </ProductImgContainer>
                                                {/* <CheckBoxContainer>
                                                    <CheckBox className="container">
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </CheckBox>
                                                </CheckBoxContainer> */}
                                            </ProductFrame>
                                            <ProductDetails>
                                                <ProductName>
                                                    Huami Amazfit
                                                </ProductName>
                                                <ProductColor>
                                                    Watch, Black
                                                </ProductColor>
                                                <SizeAndQty>
                                                    <DropDown>
                                                        Size: Onesize
                                                    </DropDown>
                                                    <DropDown>Qty: 1</DropDown>
                                                </SizeAndQty>
                                                <Price>
                                                    <CurrentPrice>
                                                        ₹&nbsp;12000
                                                    </CurrentPrice>
                                                    <MRP>
                                                        ₹&nbsp;MRP&nbsp;17000
                                                    </MRP>
                                                    <Discount>
                                                        (76 &nbsp;%&nbsp;Off)
                                                    </Discount>
                                                </Price>
                                                <ExpectedDelivery>
                                                    Delivery by{" "}
                                                    <span>19 Nov 2022</span>
                                                </ExpectedDelivery>
                                            </ProductDetails>
                                        </Product>
                                        <CloseButton>
                                            <img
                                                src={
                                                    require("../../../assets/images/product-single/close-grey.svg")
                                                        .default
                                                }
                                                alt="close"
                                            />
                                        </CloseButton>
                                    </Item>
                                </ItemsContainer>
                            </Middle>
                            <Bottom>
                                <AddMore>
                                    <WishListIconContainer>
                                        <img
                                            src={
                                                require("../../../assets/images/product-single/wishlist-orange.svg")
                                                    .default
                                            }
                                            alt="wishlist-icon"
                                        />
                                    </WishListIconContainer>
                                    <FromWishlist>
                                        Add More From Wishlist
                                    </FromWishlist>
                                </AddMore>
                                <RightArrow>
                                    <img
                                        src={
                                            require("../../../assets/images/product-single/right-arrow-grey.svg")
                                                .default
                                        }
                                        alt="Right-arrow"
                                    />
                                </RightArrow>
                            </Bottom>
                        </LeftSection>
                        <RightSection>
                            <CouponSection>
                                <CouponTitle>Coupons</CouponTitle>
                                <CouponContainer>
                                    <ApplyCoupoun>
                                        <CouponIcon>
                                            <img
                                                src={
                                                    require("../../../assets/images/product-single/coupon-icon.svg")
                                                        .default
                                                }
                                                alt="Coupon-icon"
                                            />
                                        </CouponIcon>
                                        <Apply>Apply Coupons</Apply>
                                    </ApplyCoupoun>
                                    <CouponButton
                                        onClick={() => {
                                            setShowAddress(2);
                                        }}
                                    >
                                        Apply
                                    </CouponButton>
                                </CouponContainer>
                            </CouponSection>
                            <PriceDetails>
                                <PriceTitle>
                                    Price Details ( 1 Item )
                                </PriceTitle>
                                <PricePairs>
                                    <PriceItems>
                                        <LeftKey>Total MRP</LeftKey>
                                        <RightPair>₹&nbsp;17,000</RightPair>
                                    </PriceItems>
                                    <PriceItems>
                                        <LeftKey>Total MRP</LeftKey>
                                        <RightPair>₹&nbsp;17,000</RightPair>
                                    </PriceItems>
                                    <PriceItems>
                                        <LeftKey>Coupon Discount</LeftKey>
                                        <RightPair>Apply Coupon</RightPair>
                                    </PriceItems>
                                    <PriceItems>
                                        <LeftKey>Total MRP</LeftKey>
                                        <RightPair>₹&nbsp;17,000</RightPair>
                                    </PriceItems>
                                    <PriceItems>
                                        <LeftKey>Total Amount</LeftKey>
                                        <RightPair>₹&nbsp;17,000</RightPair>
                                    </PriceItems>
                                </PricePairs>
                                <PlaceOrderButton>Place Order</PlaceOrderButton>
                            </PriceDetails>
                        </RightSection>
                    </Content>
                </Wrapper>
            </Container>
        </>
    );
}

const Container = styled.section`
    padding: 120px 0 0;
    @media all and (max-width: 768px) {
        padding: 90px 0 0;
    }
`;
const Wrapper = styled.section`
    @media all and (max-width: 640px) {
        width: 90%;
    }
`;
const Content = styled.div`
    padding-bottom: 50px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
        padding-bottom: 10px;
    }
`;
const LeftSection = styled.div`
    width: 60%;
    padding: 0 30px 0 0;
    @media all and (max-width: 1280px) {
        width: 100%;
        order: 2;
        padding: 0;
        margin-top: 30px;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 20px;
    }
`;
const Address = styled.div``;
const CustomerName = styled.p`
    b {
        font-family: "dm_sansboldmedium";
        font-size: 18px;
    }
`;
const CustomerAddress = styled.p`
    font-size: 14px;
`;
const ChangeAddressButtom = styled.div`
    cursor: pointer;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    border: 1px solid #ff5740;
    color: #ff5e48;
    margin-left: 15px;
    @media all and (max-width: 768px) {
        width: 100%;
        margin: 15px 0 0 0;
    }
`;
const Middle = styled.div``;
const ItemFunctions = styled.div`
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    @media all and (max-width: 640px) {
        align-items: start;
    }
`;
const AllItems = styled.div`
    display: flex;
`;
const AllCheckBox = styled.input`
    color: #f00;
`;
const ItemsSelected = styled.h6`
    font-family: "dm_sansboldmedium";
    font-size: 20px;
`;
const AllItemsFunctions = styled.div`
    display: flex;
`;
const AllItemButtons = styled.div`
    p {
        text-transform: uppercase;
        font-family: "dm_sansboldbold";
        font-size: 14px;
    }
    :first-child {
        border-right: 3px solid #eee;
        padding-right: 8px;
        margin-right: 8px;
    }
    img {
        display: none;
    }
    @media all and (max-width: 640px) {
        p {
            display: none;
        }
        img {
            display: block;
        }
    }
`;
const ItemsContainer = styled.div``;
const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    @media all and (max-width: 980px) {
        border: none;
        padding: 0;
    }
`;
const Product = styled.div`
    display: flex;
    align-items: center;
`;
const ProductFrame = styled.div`
    margin-right: 15px;
`;
const ProductImgContainer = styled.div`
    width: 150px;
    @media all and (max-width: 640px) {
        width: 110px;
    }
`;
const CheckBoxContainer = styled.div``;
const CheckBox = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    :hover input.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked .checkmark {
        background-color: #2196f3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
const ProductDetails = styled.div``;
const ProductName = styled.h3`
    font-family: "dm_sansboldmedium";
    font-size: 20px;
    margin-bottom: 8px;
    @media all and (max-width: 640px) {
        font-size: 16px;
        margin-bottom: 3px;
    }
`;
const ProductColor = styled.p`
    color: #777a86;
    font-size: 16px;
    margin-bottom: 5px;
    @media all and (max-width: 640px) {
        font-size: 14px;
        margin-bottom: 0;
    }
`;
const SizeAndQty = styled.div`
    display: flex;
    margin-bottom: 5px;
    @media all and (max-width: 640px) {
        margin-bottom: 2px;
        
    }
`;
const DropDown = styled.div`
    background: #f5f5f6;
    font-family: "dm_sansboldmedium";
    font-size: 14px;
    padding: 8px;
    :first-child {
        margin-right: 10px;
    }
    @media all and (max-width: 640px) {
        font-size: 12px;
    }
`;
const Price = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 0;
        
    }
`;
const CurrentPrice = styled.p`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const MRP = styled.p`
    font-size: 14px;
    color: #777a86;
    text-decoration: line-through;
    margin: 0 15px;
    @media all and (max-width: 1280px) {
        margin: 0 10px;
        font-size: 12px;
    }
    @media all and (max-width: 980px) {
        margin: 0 0 0 5px;
    }
    @media all and (max-width: 640px) {
        margin: 0;
    }
`;
const Discount = styled.p`
    font-size: 14px;
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        display: none;
    }
`;
const ExpectedDelivery = styled.p`
    span {
        font-family: "dm_sansboldmedium";
        font-size: 18px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
        span {
            font-size: 14px;
        }
    }
`;
const CloseButton = styled.div`
    width: 30px;
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 20px;
    @media all and (max-width: 640px) {
        display: none;
    }
`;
const AddMore = styled.div`
    display: flex;
    align-items: center;
`;
const WishListIconContainer = styled.div``;
const FromWishlist = styled.h5``;
const RightArrow = styled.div``;
const RightSection = styled.div`
    width: 40%;
    @media all and (max-width: 1280px) {
        width: 100%;
    }
`;
const CouponSection = styled.div`
    border-left: 1px solid #eeeeee;
    padding: 0 0 0 30px;
    margin: 0 0 30px 0;
    @media all and (max-width: 1280px) {
        border: none;
        padding: 0;
    }
`;
const CouponTitle = styled.h5`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    color: #535766;
    margin-bottom: 20px;
`;
const CouponContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px;
`;
const ApplyCoupoun = styled.div`
    display: flex;
    align-items: center;
`;
const CouponIcon = styled.div`
    margin-right: 10px;
`;
const Apply = styled.h5`
    font-family: "dm_sansboldbold";
    font-size: 16px;
`;
const CouponButton = styled.div`
    cursor: pointer;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    border: 1px solid #ff5740;
    color: #ff5e48;
`;
const PriceDetails = styled.div`
    border-left: 1px solid #eeeeee;
    padding-left: 30px;
    @media all and (max-width: 1280px) {
        border: none;
        padding: 0;
    }
`;
const PriceTitle = styled.h5`
    font-size: 18px;
    margin-bottom: 20px;
`;
const PricePairs = styled.div``;
const PriceItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    :last-child {
        margin: 10px 0 20px;
        padding: 20px 0 0;
        border-top: 1px solid #eee;
        font-family: "dm_sansboldmedium";
        font-size: 20px;
    }
`;
const LeftKey = styled.h6`
    font-size: 16px;
`;
const RightPair = styled.p`
    font-size: 16px;
`;
const PlaceOrderButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    color: #fff;
    width: 100%;
`;
