import React, { useContext, useEffect } from "react";

//packages
import { Routes, Route } from "react-router-dom";

//components
import AppRouter from "./AppRouter";

//contest
import { Context } from "../../Contexts/Store";

export default function MainRouter() {
    const {
        state: { user_data },
    } = useContext(Context);

    // changing sender email to name
    // useEffect(() => {
    //     document.tidioIdentify = {
    //         distinct_id: user_data?.id, // Unique visitor ID in your system
    //         email: user_data?.email, // visitor email
    //         name: user_data?.user_name, // Visitor name
    //         phone: user_data?.phone, //Visitor phone
    //     };
    //     if (window.tidioChatApi) {
    //         window.tidioChatApi.setVisitorData({
    //             distinct_id: user_data?.id, // Unique visitor ID in your system
    //             email: user_data?.email, // visitor email
    //             name: user_data?.user_name, // Visitor name
    //             phone: user_data?.phone, //Visitor phone
    //         });
    //     }
    // }, [user_data, window.tidioChatApi]);

    return (
        <Routes>
            <Route
                path="/*"
                element={<AppRouter />}
            />
        </Routes>
    );
}
