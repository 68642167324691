import React from "react";
import loaderAnimation from "../../../assets/lottie/logoanimatiom.json";
import styled from "styled-components";
import Lottie from "react-lottie";

export default function Loader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {},
    };

    return (
        <Container>
            <Lottie
                options={defaultOptions}
                height={180}
                width={180}
            />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`;
