import React, { useContext } from "react";

//packages
import { useNavigate, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

//contest
import { Context } from "../../Contexts/Store";

//custom-hook
import useScreenWidth from "../../general/hooks/useScreenWidth";

function HamburgerModal({ hamburgerState, setHamburgerState }) {
    const {
        dispatch,
        state: { user_data, account_details },
    } = useContext(Context);

    //to avoid browser tab height
    let windowHeight = window.innerHeight;

    const accessToken = user_data?.access_token;
    const gender = account_details?.gender;
    const firstLettersOfName = account_details?.first_letters_of_name;

    const currentPath = window.location.pathname;
    const screenWidth = useScreenWidth();
    const navigate = useNavigate();

    //logout function
    const logout = () => {
        dispatch({
            type: "LOGOUT",
            user_data: {},
        });
        navigate("/");
    };

    return (
        <>
            <MainContainer
                id="ham"
                className={hamburgerState && "active"}
            >
                <Container
                    className={hamburgerState && "active"}
                    type={windowHeight}
                >
                    {accessToken ? (
                        <TopContainer>
                            <ProfilePicBox>
                                {" "}
                                {gender === "male" ? (
                                    <img
                                        alt="male"
                                        src={
                                            require("../../../assets/images/common/male.svg")
                                                .default
                                        }
                                    />
                                ) : gender === "female" ? (
                                    <img
                                        alt="male"
                                        src={
                                            require("../../../assets/images/common/female.svg")
                                                .default
                                        }
                                    />
                                ) : (
                                    <span> {firstLettersOfName}</span>
                                )}
                            </ProfilePicBox>
                            <BottomBox>
                                <UserName>
                                    {account_details?.first_name} {account_details?.last_name}
                                </UserName>
                                <RightArrowBox
                                    to={screenWidth > 768 ? "/account/user-profile" : "/account"}
                                    onClick={() => {
                                        setHamburgerState(false);
                                    }}
                                >
                                    <img
                                        src={
                                            require("../../../assets/images/common/right-small-arrow.svg")
                                                .default
                                        }
                                        alt="right-arrow"
                                    />
                                </RightArrowBox>
                            </BottomBox>
                        </TopContainer>
                    ) : (
                        <TopContainer>
                            <ImageContainer>
                                <Image
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/humburggift.svg"
                                    }
                                />
                            </ImageContainer>
                            <DivLeftIcon>
                                <Title>Let’s Start Gifting</Title>
                            </DivLeftIcon>
                        </TopContainer>
                    )}

                    <MiddleContainer>
                        <ListItem
                            to="/"
                            onClick={() => {
                                setHamburgerState(false);
                            }}
                            className={currentPath === "/" ? "active" : ""}
                        >
                            Home
                        </ListItem>
                        <ListItem
                            to="/about"
                            onClick={() => {
                                setHamburgerState(false);
                            }}
                            className={currentPath === "/about" ? "active" : ""}
                        >
                            About Us
                        </ListItem>
                        {/* <ListItem
                            to="/careers"
                            onClick={() => {
                                setHamburgerState(false);
                            }}
                            className={currentPath === "/careers" ? "active" : ""}
                        >
                            Careers
                        </ListItem>
                        <ListItem
                            to="/corporategift"
                            onClick={() => {
                                setHamburgerState(false);
                            }}
                            className={currentPath === "/corporategift" ? "active" : ""}
                        >
                            Corporative - Gifting
                        </ListItem> */}

                        {user_data?.access_token ? (
                            <>
                                <Button>
                                    <span onClick={() => logout()}>Log Out</span>
                                </Button>
                            </>
                        ) : (
                            <Button>
                                <span onClick={() => navigate("/login")}>Login</span>
                            </Button>
                        )}
                    </MiddleContainer>
                    {accessToken ? (
                        <BottomContainer className="first">
                            <DettLogoBox to="/#home-spot">
                                <img
                                    src={
                                        require("../../../assets/images/common/dett-logo.svg")
                                            .default
                                    }
                                    alt="dett-logo"
                                />
                            </DettLogoBox>
                        </BottomContainer>
                    ) : (
                        <BottomContainer>
                            <LeftContainer>
                                <Heading>Let’s Pre-Book Gift Now !!</Heading>
                                <ButtonBottom
                                    to="/#getapp"
                                    onClick={() => setHamburgerState(false)}
                                >
                                    Download App
                                </ButtonBottom>
                            </LeftContainer>
                            <RightContainer>
                                <ImageMobile>
                                    <Mobile
                                        src={
                                            "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/humburgbackground.png"
                                        }
                                        alt="mobile-bg"
                                    />
                                </ImageMobile>
                            </RightContainer>
                        </BottomContainer>
                    )}
                </Container>
                {hamburgerState && <OverLay onClick={() => setHamburgerState(false)}></OverLay>}
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 100;
    left: 0;
    top: 0;
    overflow: hidden;
    transform: translateX(-100%);

    @media all and (max-width: 980px) {
        &.active {
            transform: translateX(0);
        }
    }
`;

const ProfilePicBox = styled.div`
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    font-size: 22px;
    margin-bottom: 15px;
`;

const BottomBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const UserName = styled.h2`
    font-size: 18px;
    color: #282c3f;
    text-transform: capitalize;
    font-family: "dm_sansboldmedium";
`;

const RightArrowBox = styled(Link)`
    width: 15px;
    height: 15px;
    filter: invert(53%) sepia(21%) saturate(5039%) hue-rotate(329deg) brightness(99%) contrast(113%);
`;

const OverLay = styled.div`
    flex: 1;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;
const Container = styled.div`
    width: 350px;
    min-height: ${({ type }) => type && type + "px"};

    z-index: 101;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transform: translateX(-370px);
    transition: all 0.5s ease-in-out;
    &.active {
        transform: translateX(0px);
        transition: all 0.5s ease-in-out;
    }

    @media all and (max-width: 640px) {
        width: 300px;
    }
    @media all and (max-width: 480px) {
        width: 250px;
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: linear-gradient(100.06deg, #ffefed -9.49%, rgba(255, 246, 245, 0.22) 96.97%);
    padding: 20px;
`;
const MiddleContainer = styled.div`
    padding: 20px;
    flex: 1;
`;

const Button = styled.span`
    font-size: 16px;
    color: #282c3f;
    font-family: "dm_sansboldmedium";
    cursor: pointer;
    display: inline-block;
    &:hover {
        color: #ff7d6c;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(100.06deg, #ffefed -9.49%, rgba(255, 246, 245, 0.22) 96.97%);
    position: relative;
    overflow: hidden;
    bottom: 0;
    &.first {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }
`;

const DettLogoBox = styled(HashLink)`
    cursor: pointer;
    width: 60px;
    height: 60px;
`;

const ImageContainer = styled.div`
    width: 40%;
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const Title = styled.h4`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    width: 75%;
    margin-bottom: 20px;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const ListItem = styled(Link)`
    color: #282c3f;
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    font-family: "dm_sansboldmedium";
    &.active {
        color: #ff5740;
    }
    &:hover {
        color: #ff7d6c;
    }
`;

const Items = styled.div``;

const ProfileTitle = styled.h2`
    font-size: 16px;
    margin-bottom: 10px;
    font-family: "dm_sansboldmedium";
`;

const LeftContainer = styled.div`
    width: 60%;
    padding: 30px;
    @media all and (max-width: 540px) {
        padding: 20px;
    }
    @media all and (max-width: 481px) {
        padding: 10px;
    }
`;
const Heading = styled.h3`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    width: 75%;
    margin-bottom: 20px;
    @media all and (max-width: 980px) {
        font-size: 18px;
        width: 90%;
    }
    @media all and (max-width: 640px) {
        font-size: 18px;
        width: 100%;
    }
`;
const ButtonBottom = styled(HashLink)`
    width: 118px;
    height: 36px;
    cursor: pointer;
    border-radius: 0px;
    font-size: 14px;
    align-items: center;
    display: flex;
    text-align: center;
    color: #fff;
    justify-content: center;
    font-family: "dm_sansboldmedium";

    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    border-radius: 5px;
`;
const RightContainer = styled.div`
    width: 30%;
`;
const ImageMobile = styled.div`
    width: 46%;
    position: absolute;
    bottom: -50px;
    right: -34px;
    @media all and (max-width: 480px) {
        width: 48%;
    }
`;
const Mobile = styled.img`
    width: 100%;
    display: block;
`;
const DivLeftIcon = styled.div`
    width: 55%;
`;

export default HamburgerModal;
