import React from "react";
import styled from "styled-components";
import HomeFooterpage from "../home/HomeFooterpage";

export default function SimilarProducts() {
    //fake data
    const similardata = [
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-two.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-three.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-four.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-five.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-six.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-seven.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-eight.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-four.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-five.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-six.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
        {
            productname: "Huami Amazfit",
            image: require("../../../assets/images/wishlist/watch-seven.png"),
            productcolor: "Watch, Black",
            currentprice: "12000",
            mrp: "17000",
            discount: "76",
        },
    ];

    return (
        <>
            <Container>
                <Wrapper className="wrapper">
                    <Content>
                        <Title>Similar Products</Title>
                        <ProductList>
                            {similardata.map((item) => {
                                return (
                                    <ProductItem>
                                        <ProductImgContainer>
                                            <img
                                                src={item.image}
                                                alt="similar-product"
                                            />
                                        </ProductImgContainer>
                                        <ProductName>
                                            {item.productname}
                                        </ProductName>
                                        <ProductColor>
                                            {item.productcolor}
                                        </ProductColor>
                                        <Price>
                                            <CurrentPrice>
                                                ₹&nbsp;{item.currentprice}
                                            </CurrentPrice>
                                            <MRP>₹&nbsp;MRP&nbsp;{item.mrp}</MRP>
                                            <Discount>
                                                ({item.discount}&nbsp;%&nbsp;Off)
                                            </Discount>
                                        </Price>
                                    </ProductItem>
                                );
                            })}
                        </ProductList>
                    </Content>
                </Wrapper>
            </Container>
            <HomeFooterpage />
        </>
    );
}

const Container = styled.section`
    padding: 20px 0 50px;
    border-bottom: 1px solid #EEEEEE
`;
const Wrapper = styled.section``;
const Content = styled.div``;
const Title = styled.h3`
    font-family: "dm_sansboldmedium";
    font-size: 20px;
    margin-bottom: 10px;
`;
const ProductList = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    @media all and (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media all and (max-width: 480px) {
        grid-gap: 10px;
    }
`;
const ProductItem = styled.div`
    width: 100%;
`;
const ProductImgContainer = styled.div`
    img {
        border-radius: 4px;
    }
`;
const ProductName = styled.h4`
    font-family: "dm_sansboldmedium";
    font-size: 18px;
    margin-top: 8px;
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
`;
const ProductColor = styled.p`
    color: #777a86;
    font-size: 14px;
    margin: 3px 0;
`;
const Price = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const CurrentPrice = styled.p`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const MRP = styled.p`
    font-size: 14px;
    color: #777a86;
    text-decoration: line-through;
    margin: 0 15px;
    @media all and (max-width: 1280px) {
        margin: 0 10px;
        font-size: 12px;
    }
    @media all and (max-width: 980px) {
        margin: 0 0 0 5px;
    }
    @media all and (max-width: 640px) {
        margin: 0;
    }
`;
const Discount = styled.p`
    font-size: 14px;
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        display: none;
    }
`;
