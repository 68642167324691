import React, { useState } from "react";
import styled from "styled-components";
import watchsingle from "../../../assets/images/product-single/watch-single.png";
import watchgrid1 from "../../../assets/images/product-single/watch-grid1.png";
import watchgrid2 from "../../../assets/images/product-single/watch-grid2.png";

export default function ProductSpotlight() {
    const [imageUrl, setImageUrl] = useState(watchsingle);

    return (
        <Container>
            <Wrapper className="wrapper">
                <Content>
                    <LeftSection>
                        <LeftContainer>
                            <Top>
                                <GridImages>
                                    <ProductImages
                                        onMouseOver={() => {
                                            setImageUrl(watchsingle);
                                        }}
                                    >
                                        <img
                                            src={watchsingle}
                                            alt="product-grid"
                                        />
                                    </ProductImages>
                                    <ProductImages
                                        onMouseOver={() => {
                                            setImageUrl(watchgrid1);
                                        }}
                                    >
                                        <img
                                            src={watchgrid1}
                                            alt="product-grid"
                                        />
                                    </ProductImages>
                                    <ProductImages
                                        onMouseOver={() => {
                                            setImageUrl(watchgrid2);
                                        }}
                                    >
                                        <img
                                            src={watchgrid2}
                                            alt="product-grid"
                                        />
                                    </ProductImages>

                                    <ProductImages
                                        onMouseOver={() => {
                                            setImageUrl(watchsingle);
                                        }}
                                    >
                                        <img
                                            src={watchsingle}
                                            alt="product-grid"
                                        />
                                    </ProductImages>
                                    <ProductImages
                                        onMouseOver={() => {
                                            setImageUrl(watchgrid1);
                                        }}
                                    >
                                        <img
                                            src={watchgrid1}
                                            alt="product-grid"
                                        />
                                    </ProductImages>
                                    <ProductImages
                                        onMouseOver={() => {
                                            setImageUrl(watchgrid2);
                                        }}
                                    >
                                        <img
                                            src={watchgrid2}
                                            alt="product-grid"
                                        />
                                    </ProductImages>
                                </GridImages>
                                <ProductSingleContainer>
                                    <ProductImg
                                        src={imageUrl}
                                        alt="Product-Single-Image"
                                    />
                                </ProductSingleContainer>
                            </Top>
                            <Bottom>
                                <BottomButton className="cart-button">
                                    Add to Cart
                                    <IconContainer>
                                        <img
                                            src={
                                                require("../../../assets/images/product-single/cart-icon.svg")
                                                    .default
                                            }
                                            alt="cart-icon"
                                        />
                                    </IconContainer>
                                </BottomButton>
                                <BottomButton className="wishlist-button">
                                    Wishlist Now
                                    <IconContainer>
                                        <img
                                            src={
                                                require("../../../assets/images/product-single/wishlist-icon.svg")
                                                    .default
                                            }
                                            alt=""
                                        />
                                    </IconContainer>
                                </BottomButton>
                            </Bottom>
                        </LeftContainer>
                    </LeftSection>
                    <RightSection>
                        <ProductName>G-Shock</ProductName>
                        <ProductDescription>
                            Men Casio G-Shock, MD-13DD,<br />Colour, Black
                        </ProductDescription>
                        <Price>
                            <CurrentPrice>₹ 12000</CurrentPrice>
                            <MRP>₹ MRP 17000</MRP>
                            <Discount>(76 % Off)</Discount>
                        </Price>
                        <Taxes>Including all Taxes</Taxes>
                        <Size>
                            <SelectSize>Select Size</SelectSize>
                            <SizeChart>
                                Size Chart
                                <RightArrowContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/product-single/right-arrow-orange.svg")
                                                .default
                                        }
                                        alt="right-arrow"
                                    />
                                </RightArrowContainer>
                            </SizeChart>
                        </Size>
                        <AvailableSizes>One Size</AvailableSizes>
                        <Delivery>Delivery Options</Delivery>
                        <DeliveryCheck>
                            <PinInput placeholder="Enter pin code" />
                            <CheckButton>Check</CheckButton>
                        </DeliveryCheck>
                        <ExpectedDelivery>
                            <span>Delivery Expected In</span> 24 - 29 Nov, 2022
                        </ExpectedDelivery>
                        <ProductDetails>
                            <DetailsTitle>Product Details</DetailsTitle>
                            <DetailsIcon>
                                <img
                                    src={
                                        require("../../../assets/images/product-single/details-icon.svg")
                                            .default
                                    }
                                    alt="details-icon"
                                />
                            </DetailsIcon>
                        </ProductDetails>
                        <ProductContent>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Praesentium saepe libero
                                cupiditate impedit ab sequi harum quaerat nobis
                            </p>
                        </ProductContent>
                    </RightSection>
                </Content>
            </Wrapper>
        </Container>
    );
}

const Container = styled.section`
    padding: 120px 0 50px;
    @media all and (max-width: 980px) {
        padding: 90px 0 10px;
    }
`;
const Wrapper = styled.section``;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const LeftSection = styled.div`
    width: 48%;
    position: relative;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const LeftContainer = styled.div`
    position: sticky;
    top: 120px;
`;
const Top = styled.div`
    height: 400px;
    display: flex;
    align-items: center;
    @media all and (max-width: 1080px) {
        width: 300px;
    }
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
    }
`;
const GridImages = styled.div`
    margin-right: 50px;
    max-height: 400px;
    overflow-y: scroll;
    @media all and (max-width: 1280px) {
        margin-right: 40px;
    }
    @media all and (max-width: 980px) {
        order: 2;
        width: 80%;
        display: flex;
        margin: 0 auto;
        justify-content: space-evenly;
        overflow-x: scroll;
        /* max-width: 230px; */
    }
`;
const ProductImages = styled.div`
    width: 100px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-bottom: 20px;
    &:hover {
        border: 1px solid #ff5e48;
    }
    @media all and (max-width: 980px) {
        min-width: 60px;
        max-width: 60px;
        margin: 0 10px 0 0;
        :first-child {
            margin-left: 30px;
        }
        :last-child {
            margin: 0;
        }
    }
`;
const ProductSingleContainer = styled.div`
    width: 300px;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 250px;
    }
    @media all and (max-width: 1080px) {
        width: 200px;
    }
`;
const ProductImg = styled.img`
    /* max-width: 548px; */
    /* max-height: 548px; */
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media all and (max-width: 980px) {
        margin-top: 20px;
    }
`;
const BottomButton = styled.div`
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    &.cart-button {
        background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
        color: #fff;
    }
    &.wishlist-button {
        border: 1px solid #ff5740;
        color: #ff5e48;
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        width: 49%;
    }
`;
const IconContainer = styled.div`
    margin-left: 8px;
    width: 20px;
`;
const RightSection = styled.div`
    width: 48%;
    @media all and (max-width: 980px) {
        width: 100%;
        margin-top: 40px;
    }
`;
const ProductName = styled.h2`
    font-family: "dm_sansboldbold";
    font-size: 28px;
    margin-bottom: 5px;
    @media all and (max-width: 1280px) {
        font-size: 24px;
    }
`;
const ProductDescription = styled.p`
    color: #777a86;
    font-size: 16px;
    /* width: 50%; */
    @media all and (max-width: 1280px) {
        font-size: 14px;
        /* width: 60%; */
    }
`;
const Price = styled.div`
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #d4d4d4;
    @media all and (max-width: 480px) {
        padding-top: 10px;
        margin-top: 10px;
    }
`;
const CurrentPrice = styled.p`
    font-family: "dm_sansboldmedium";
    font-size: 22px;
    @media all and (max-width: 1280px) {
        font-size: 20px;
    }
`;
const MRP = styled.p`
    font-size: 16px;
    color: #777a86;
    text-decoration: line-through;
    margin: 0 15px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 980px) {
        margin: 0 10px;
    }
`;
const Discount = styled.p`
    font-size: 16px;
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const Taxes = styled.p`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    color: #009262;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const Size = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;
const SelectSize = styled.p`
    font-family: "dm_sansboldmedium";
    font-size: 20px;
    margin-right: 10px;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
`;
const SizeChart = styled.div`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    color: #ff5e48;
    white-space: nowrap;
    display: flex;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const RightArrowContainer = styled.div`
    margin-left: 10px;
    width: 6px;
    @media all and (max-width: 980px) {
        margin-left: 3px;
    }
`;
const AvailableSizes = styled.span`
    display: inline-block;
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    border: 1px solid #ff5e48;
    border-radius: 24px;
    padding: 8px;
    margin-bottom: 20px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const Delivery = styled.p`
    font-family: "dm_sansboldmedium";
    font-size: 20px;
    margin-bottom: 5px;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
`;
const DeliveryCheck = styled.div`
    display: inline-flex;
    border: 1px solid #d4d4d4;
    padding: 12px;
    border-radius: 4px;
`;
const PinInput = styled.input`
    font-size: 14px;
`;
const CheckButton = styled.div`
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const ExpectedDelivery = styled.p`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-bottom: 20px;
    span {
        color: #009262;
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const ProductDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const DetailsTitle = styled.h4`
    font-family: "dm_sansboldmedium";
    font-size: 20px;
    margin-right: 10px;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
`;
const DetailsIcon = styled.div`
    width: 14px;
`;
const ProductContent = styled.div`
    p {
        font-size: 16px;
        margin-bottom: 20px;
        :last-child {
            margin-bottom: 0;
        }
        @media all and (max-width: 1280px) {
            font-size: 14px;
        }
        @media all and (max-width: 980px) {
            margin-bottom: 15px;
        }
    }
`;
