import React from "react";

//packages
import styled from "styled-components";

//components
import HomeFooterpage from "../home/HomeFooterpage";
import ReusableButton from "../../general/ReusableButton";

export default function ShareMemories() {
    //smooth scrolling function
    const to = "#gift-employee";
    const scrollToTop = (event) => {
        event.preventDefault();
        const element = document.querySelector(to);
        element.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <>
            <Container>
                <Wrapper className="wrapper">
                    <Content>
                        <LeftSection>
                            <MemoriesImg
                                src={require("../../../assets/images/corporate-gifting/memories.png")}
                                alt="memories-img"
                            />
                        </LeftSection>
                        <RightSection>
                            <Quote>Share Your Memories!</Quote>
                            <Heading>Why Corporate Gifting?</Heading>
                            <MemoriesContent>
                                Corporate gifting is an important part of building and maintaining
                                strong business relationships.By providing unique and personalized
                                gifts, businesses can stand out and create a memorable experience
                                for the recipient and create a more positive work environment
                            </MemoriesContent>
                            <SendButton onClick={scrollToTop}>
                                <ReusableButton title={"Send"} />
                            </SendButton>
                        </RightSection>
                    </Content>
                </Wrapper>
            </Container>
            <HomeFooterpage />
        </>
    );
}

const Container = styled.section`
    padding: 65px 0 100px;
    @media all and (max-width: 1280px) {
        padding: 25px 0 80px;
    }
    @media all and (max-width: 980px) {
        padding: 25px 0 40px;
    }
    @media all and (max-width: 768px) {
        padding: 15px 0 35px;
    }
`;
const Wrapper = styled.section``;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 61px 0px #00000012;
    padding: 38px;
    border-radius: 16px;
    @media all and (max-width: 1280px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 480px) {
        box-shadow: none;
        padding: 0;
    }
`;
const LeftSection = styled.div`
    width: 53%;
    @media all and (max-width: 1280px) {
        width: 80%;
        margin: 0 auto 30px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const MemoriesImg = styled.img``;
const RightSection = styled.div`
    width: 40%;
    @media all and (max-width: 1280px) {
        width: 100%;
    }
`;
const Quote = styled.h4`
    color: #ff5e48;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-bottom: 20px;
`;
const Heading = styled.h3`
    font-size: 36px;
    width: 80%;
    font-family: "dm_sansboldbold";
    color: #282c3f;
    margin-bottom: 20px;
    @media all and (max-width: 1280px) {
        width: 100%;
    }
`;
const MemoriesContent = styled.p`
    color: #777a86;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 1390px) {
        font-size: 14px;
    }
`;
const SendButton = styled.div`
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;
