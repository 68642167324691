import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NewAddressModal from "./NewAddressModal";

function DeliveryAddressModal({ showAddress, setShowAddress }) {
    return (
        <MainContainer>
            {showAddress == 0 ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: showAddress == 0 && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <SubContainer>
                            <HeadingContainer>
                                <Heading>Change Delivery Address</Heading>
                                <CloseButton>
                                    <Close
                                        src={require("../../../assets/images/cartImages/ButtonWhite.png")}
                                        alt="plus"
                                        onClick={() => {
                                            setShowAddress();
                                        }}
                                    />
                                </CloseButton>
                            </HeadingContainer>

                            <PinCodeContainer>
                                <PinCodeForm>
                                    <Input
                                        type="number"
                                        placeholder="Enter Pincode"
                                    />
                                    <CheckButton>Check</CheckButton>
                                </PinCodeForm>
                            </PinCodeContainer>

                            <OR>OR</OR>

                            <AddressDetails>
                                <Left>
                                    <Div></Div>
                                </Left>

                                <Right>
                                    <Name>
                                        Ajmal Sakeer <A>(Default)</A>
                                    </Name>
                                    <Address>
                                        Pushpa Vilasom, Venchempu (Po), Punalur, Kollam{" "}
                                    </Address>
                                    <Pin>Pin : 691333</Pin>
                                </Right>
                            </AddressDetails>

                            <AddressDetails>
                                <Left>
                                    <Div></Div>
                                </Left>

                                <Right>
                                    <Name>Shiyas Khan</Name>
                                    <Address>Pulath, Chulliyod (Po) Malappuram </Address>
                                    <Pin>Pin : 679332</Pin>
                                </Right>
                            </AddressDetails>

                            <NewAddressContainer onClick={() => setShowAddress(1)}>
                                <NewAddress>
                                    <PlusButton>
                                        <Plus
                                            src={require("../../../assets/images/cartImages/PlusImage.png")}
                                            alt="plus"
                                        />
                                    </PlusButton>
                                    <AddAddress>Add New Address</AddAddress>
                                </NewAddress>
                            </NewAddressContainer>
                        </SubContainer>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div``;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    right: 0;
    z-index: 1000;
    left: 0;
    top: 50%;
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 32%;
    position: relative;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #f5f5f6;

    @media all and (max-width: 1150px) {
        width: 40%;
    }

    @media all and (max-width: 980px) {
        width: 55%;
    }

    @media all and (max-width: 768px) {
        width: 65%;
    }

    @media all and (max-width: 640px) {
        width: 80%;
    }

    @media all and (max-width: 480px) {
        width: 85%;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 67vh; */
    margin: 0 auto;
    border-radius: 8px;
`;

const SubContainer = styled.div`
    width: 100%;
    padding: 20px;
`;

const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #fff;
    height: 55px;
    border-radius: 4px;
`;

const Heading = styled.h1`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-left: 10px;
`;

const CloseButton = styled.div`
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const Close = styled.img`
    width: 100%;
    display: block;
`;

const PinCodeContainer = styled.div`
    /* height: 10vh; */
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PinCodeForm = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
`;

const Input = styled.input`
    display: inline-block;
    font-size: 14px;
`;

const CheckButton = styled(Link)`
    color: #ff5e48;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const OR = styled.h1`
    font-size: 16px;
    color: #777a86;
    font-family: "dm_sansboldbold";
    text-align: center;
    margin-bottom: 20px;
`;

const AddressDetails = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background: #fff;
    padding: 10px 0px 10px 0px;
`;

const Left = styled.div`
    width: 10%;
`;

const Div = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #89939e;
`;

const Right = styled.div`
    width: 78%;
`;

const Name = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;

const A = styled.span``;
const Address = styled.p`
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const Pin = styled.h2`
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const NewAddressContainer = styled.div`
    background: #fff;
    /* height: 10vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const NewAddress = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    cursor: pointer;

    @media all and (max-width: 1280px) {
        padding: 5px;
    }
`;

const PlusButton = styled.div`
    width: 30px;
    margin-right: 10px;

    @media all and (max-width: 480px) {
        width: 25px;
    }
`;

const Plus = styled.img`
    width: 100%;
    display: block;
`;

const AddAddress = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    text-align: center;

    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

export default DeliveryAddressModal;
