import React, { useState } from "react";

//packages
import styled from "styled-components";

//axios
import { dettConfig } from "../../../axiosConfig";

//components
import ButtonLoader from "../../general/loader/ButtonLoader";
import ReusableButton from "../../general/ReusableButton";

export default function GiftEmployees() {
    //form field states
    const [employeeName, setEmployeeName] = useState("");
    const [employeePhoneNumber, setEmployeePhoneNumber] = useState("");
    const [emailID, setEmailID] = useState("");
    const [message, setMessage] = useState("");

    //form error message states
    const [nameErrorMsg, setNameErrorMsg] = useState("");
    const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [messageErrorMsg, setMessageErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    // validating employee name
    const handleNameChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setEmployeeName(value.trimStart());
        }
    };

    //phone number condition check
    const handlePhoneNumberChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setEmployeePhoneNumber(event.target.value);
        }
    };

    //valuating form fields
    const evaluteFields = () => {
        var error = "";
        if (employeeName === "") {
            error = "true";
            setNameErrorMsg("This field is required");
        } else {
            error = "";
            setNameErrorMsg("");
        }
        if (employeePhoneNumber === "") {
            error = "true";
            setPhoneErrorMsg("This field is required");
        } else if (employeePhoneNumber.length < 10) {
            error = "true";
            setPhoneErrorMsg("Enter a valid phone number");
        } else {
            error = "";
            setPhoneErrorMsg("");
        }
        if (emailID === "") {
            error = "true";
            setEmailErrorMsg("This field is required");
        } else if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailID) &&
            !emailID.includes(".com")
        ) {
            error = "true";
            setEmailErrorMsg("Enter a valid email");
        } else {
            error = "";
            setEmailErrorMsg("");
        }
        if (message === "") {
            error = "true";
            setMessageErrorMsg("This field is required");
        } else {
            error = "";
            setMessageErrorMsg("");
        }
        return error;
    };

    //send message form api
    const sendMessage = async () => {
        setLoading(true);
        const error = evaluteFields();
        error !== "true" &&
            (await dettConfig
                .post("api/v1/enquiries/corporate-enquiries/create/", {
                    phone: employeePhoneNumber,
                    name: employeeName,
                    emailID: emailID,
                    message: message,
                })
                .then((response) => {
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                }));
        setLoading(false);
    };
    return (
        <Container id="gift-employee">
            <Wrapper className="wrapper">
                <Content>
                    <Top>
                        <Heading>Gift your Employees</Heading>
                        <Description>
                            As a token of appreciation and love for your hardworking and dedicated
                            employees, start gifting to express your gratitude and to motivate them.
                        </Description>
                    </Top>
                    <Bottom>
                        <LeftSection>
                            <SendGiftImg
                                src={require("../../../assets/images/corporate-gifting/send-gift.png")}
                                alt="send-gift"
                            />
                        </LeftSection>
                        <RightSection>
                            <SendGiftForm
                                action=""
                                method="post"
                            >
                                <InputContainer>
                                    <Title>Name</Title>
                                    <ContentInput
                                        type="text"
                                        placeholder="Enter your Name"
                                        value={employeeName}
                                        onChange={(e) => {
                                            handleNameChange(e);
                                            setNameErrorMsg("");
                                        }}
                                    />
                                    <Error>{nameErrorMsg}</Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Phone</Title>
                                    <ContentInput
                                        type="text"
                                        placeholder="Enter your phone number"
                                        value={employeePhoneNumber}
                                        onChange={(e) => {
                                            handlePhoneNumberChange(e);
                                            setPhoneErrorMsg("");
                                        }}
                                    />
                                    <Error>{phoneErrorMsg}</Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>E-Mail</Title>
                                    <ContentInput
                                        type="email"
                                        placeholder="Enter your Email ID"
                                        value={emailID}
                                        onChange={(e) => {
                                            setEmailID(e.target.value);
                                            setEmailErrorMsg("");
                                        }}
                                    />
                                    <Error>{emailErrorMsg}</Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Message</Title>
                                    <MessageInput
                                        placeholder="Type a message..."
                                        name="sendgiftmessage"
                                        rows="4"
                                        cols="50"
                                        value={message}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                            setMessageErrorMsg("");
                                        }}
                                    />
                                    <Error>{messageErrorMsg}</Error>
                                </InputContainer>
                                <SendButton>
                                    {loading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <ReusableButton
                                            title={"Send"}
                                            onClickFunction={sendMessage}
                                        />
                                    )}
                                </SendButton>
                            </SendGiftForm>
                        </RightSection>
                    </Bottom>
                </Content>
            </Wrapper>
        </Container>
    );
}

const Container = styled.section`
    padding: 50px 0;
    @media all and (max-width: 1280px) {
        padding: 40px 0;
    }
    @media all and (max-width: 980px) {
        padding: 35px 0;
    }
    @media all and (max-width: 768px) {
        padding: 30px 0;
    }
`;
const Wrapper = styled.section``;
const Content = styled.div``;
const Top = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
`;
const Heading = styled.h1`
    font-size: 50px;
    color: #282c3f;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    @media all and (max-width: 1390px) {
        font-size: 44px;
    }
    @media all and (max-width: 768px) {
        font-size: 36px;
    }
    @media all and (max-width: 480px) {
        font-size: 30px;
    }
    @media all and (max-width: 360px) {
        font-size: 28px;
    }
`;
const Description = styled.p`
    width: 57%;
    margin: 0 auto;
    font-size: 16px;
    color: #777a86;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 980px) {
        width: 85%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        font-size: 14px;
    }
`;
const Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 6px 61px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    padding: 38px;
    margin-top: 50px;
    @media all and (max-width: 1280px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 1080px) {
        margin-top: 30px;
    }
    @media all and (max-width: 480px) {
        box-shadow: none;
        padding: 0;
    }
`;
const LeftSection = styled.div`
    width: 50%;
    @media all and (max-width: 1280px) {
        width: 80%;
        margin: 0 auto 30px;
    }
`;
const SendGiftImg = styled.img``;
const RightSection = styled.div`
    width: 48%;
    @media all and (max-width: 1280px) {
        width: 100%;
    }
`;
const SendGiftForm = styled.form`
    padding: 25px;
    background: #fff9f8;
    border-radius: 16px;
`;
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    :first-child {
        margin-top: 0;
    }
`;
const Title = styled.label`
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 1390px) {
        font-size: 16px;
    }
`;
const ContentInput = styled.input`
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    margin-top: 5px;
    font-size: 16px;
    color: #282c3f;
    &:focus {
        outline: 2px solid #ff5e48;
    }
    @media all and (max-width: 480px) {
        padding: 12px;
        font-size: 14px;
    }
`;
const MessageInput = styled.textarea`
    padding: 20px;
    font-size: 16px;
    margin-top: 5px;
    background: #fff;
    max-width: 100%;
    overflow-y: scroll;
    border-radius: 8px;
    &:focus {
        outline: 2px solid #ff5e48;
    }
    @media all and (max-width: 1390px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        padding: 12px;
    }
`;
const Error = styled.p`
    font-size: 14px;
    color: red;
    font-family: "dm_sansregular";
    position: absolute;
    bottom: -25px;
    right: 0;
`;
const SendButton = styled.div`
    cursor: pointer;
    color: #fff;
    margin-top: 30px;
    height: 45px;
    width: 130px;
    border-radius: 5px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    display: flex;
    justify-content: center;
    align-items: center;
`;
