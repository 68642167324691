import React from "react";
import styled from "styled-components";
import DettLandingPageHeader from "../includes/header/DettLandingPageHeader";
import CorporateGiftingSpotlight from "./corporate-gifting/CorporateGiftingSpotlight";
import GiftEmployees from "./corporate-gifting/GiftEmployees";
import Partners from "./corporate-gifting/Partners";
import ShareMemories from "./corporate-gifting/ShareMemories";

export default function CorporateGifting() {
    return (
        <Container>
            <DettLandingPageHeader />
            <CorporateGiftingSpotlight />
            <GiftEmployees />
            <Partners />
            <ShareMemories />
        </Container>
    );
}

const Container = styled.section`
    :-webkit-scrollbar {
        width: 20px;
        /* display: none; */
    }
`;